import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Modal } from 'react-bootstrap';
import { Switch } from '../../ui/switch/switch';
import { RedLabel } from '../styled-components';
import { useAuthContext } from '../../auth/auth-context';
import {
  getFinancialSuspensions,
  getSuspensionRules,
  SuspensionRule,
  updateFinancialSuspension,
} from '../../../shared/api/suspensions';

type ConfirmationDialogProps = {
  show: boolean;
  onHide: () => void;
  onSuspend: () => void;
};

const ConfirmationDialog = ({
  show,
  onHide,
  onSuspend,
}: ConfirmationDialogProps) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Financial Suspension</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you want to suspend this learner?</Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={onHide}>
        Cancel
      </Button>
      <Button onClick={onSuspend}>Suspend</Button>
    </Modal.Footer>
  </Modal>
);

type Props = {
  learnerId: string;
};

const Content = ({ learnerId }: Props) => {
  const { getBearerToken } = useAuthContext();
  const [showModal, setShowModal] = useState(false);

  const { data: suspensions } = useQuery({
    queryKey: ['financialSuspensions', learnerId, 'list'],
    queryFn: () => getFinancialSuspensions(learnerId, getBearerToken),
    suspense: true,
  });

  const manualSuspension = suspensions?.currentSuspensions.find(
    (s) => s.suspension === 'Manual',
  );
  const isSuspended = manualSuspension?.shouldBeSuspended;
  const [enabled, setEnabled] = useState(isSuspended || false);

  const { mutate } = useMutation({
    mutationFn: (isDisabled: boolean) => updateFinancialSuspension(learnerId, { isDisabled }, getBearerToken),
  });

  const handleUpdate = (value: boolean) => {
    setEnabled(value);
    mutate(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isDisabled = event.target.checked;

    if (isDisabled) {
      setShowModal(true);
      return;
    }

    handleUpdate(false);
  };

  const handleSuspend = () => {
    setShowModal(false);
    handleUpdate(true);
  };

  return (
    <>
      <Switch
        checked={enabled}
        label={<RedLabel>Financial Suspension</RedLabel>}
        onChange={handleChange}
      />
      <ConfirmationDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        onSuspend={handleSuspend}
      />
    </>
  );
};

export const FinancialSuspension = ({ learnerId }: Props) => {
  const { getBearerToken } = useAuthContext();

  const { data: rules } = useQuery({
    queryKey: ['financialSuspensions', learnerId, 'rules'],
    queryFn: () => getSuspensionRules(learnerId, getBearerToken),
    suspense: true,
  });

  if (!rules?.includes(SuspensionRule.Manual)) return null;

  return <Content learnerId={learnerId} />;
};
