export enum SuspensionRule {
  AEP = 'AEP',
  Financial = 'Financial',
  Manual = 'Manual',
}

export const getSuspensionRules = async (
  learnerId: string,
  getBearerToken: () => Promise<string>,
): Promise<SuspensionRule[]> => {
  const token = await getBearerToken();
  const response = await fetch(
    `/api/suspensions-api/v1/learners/${learnerId}/suspensions/rules`,
    {
      method: 'GET',
      headers: [['Authorization', `Bearer ${token}`]],
    },
  );

  if (!response.ok) throw await response;

  const rules = await response.json();
  return rules;
};

export type FinancialSuspension = {
  suspension: string;
  shouldBeSuspended: boolean;
  timestamp: string;
  possibleSuspensionDate: string;
};

export type FinancialSuspensionResponse = {
  status: string;
  currentSuspensions: FinancialSuspension[];
};

export const getFinancialSuspensions = async (
  learnerId: string,
  getBearerToken: () => Promise<string>,
): Promise<FinancialSuspensionResponse> => {
  const token = await getBearerToken();
  const response = await fetch(
    `/api/suspensions-api/v1/learners/${learnerId}/suspensions`,
    {
      method: 'GET',
      headers: [['Authorization', `Bearer ${token}`]],
    },
  );
  if (!response.ok) throw await response;

  const suspensions = await response.json();
  return suspensions;
};

export const updateFinancialSuspension = async (
  applicantId: string,
  payload: {
    isDisabled: boolean;
  },
  getBearerToken: () => Promise<string>,
) => {
  const token = await getBearerToken();
  const response = await fetch(
    `/api/suspensions-api/v1/learners/${applicantId}/suspensions/disabled`,
    {
      method: 'POST',
      headers: [['Authorization', `Bearer ${token}`]],
      body: JSON.stringify(payload),
    },
  );

  if (!response.ok) throw await response;

  return response;
};
