import React from 'react';
import { Form as BootstrapForm } from 'react-bootstrap';
import { FormCard } from '../form-card/form-card';
import { FormField } from '../../../../components/form-field/form-field';
import styles from '../form-content.module.scss';
import {
  required,
  validateURL,
  validateIf,
} from '../../../../../../utils/validate.utils';
import { usePropertyValues } from '../../../hooks/use-property-values';

export const ProductsForm = () => {
  const { data: propertyValues } = usePropertyValues();

  return (
    <FormCard title="Products">
      <FormField
        name="tuitionFeeInvoiceType"
        label="Tuition Invoicing:"
        validate={required}
        validateFields={['tuitionFeePaymentUrl']}
        render={({ input }) => (
          <div className={styles.column}>
            {propertyValues?.tuitionInvoiceTypes.map(({ id, name }) => (
              <div key={id} className={styles.row}>
                <BootstrapForm.Check
                  key={`${id}-radio`}
                  type="radio"
                  name="tuition-invoicing"
                  label={name}
                  value={id}
                  onChange={() => input.onChange(id)}
                  checked={input.value === id}
                  data-testid={`tuition-invoicing-${id}`}
                />
                {id === 'PartnerBillsLearner' && (
                  <FormField<string>
                    key={`${id}-url`}
                    name="tuitionFeePaymentUrl"
                    label="Payment URL"
                    disabled={input.value !== 'PartnerBillsLearner'}
                    validate={(value, allValues) => validateIf(
                      (allValues as any).tuitionFeeInvoiceType
                          === 'PartnerBillsLearner',
                      validateURL,
                    )(value)}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      />
    </FormCard>
  );
};
