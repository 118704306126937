import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { MainRow } from '../start-date/start-date-modal/styled-components';
import { KEEP_VALUE, PARTNER, SELECT } from './enrollment-modal.constants';
import {
  ConditionalField,
  renderSelectField,
} from '../../helper-components/form-components/form-filed-components/form-filed.components';
import { usePartners } from '../../manage-partners/hooks/use-partners';
import { ISelectOption } from '../../../models/SelectOptions';
import { IConditionalField } from '../../helper-components/form-components/form-filed-components/form-filed.models';

type Props = {
  changeValue: IConditionalField['changeValue'];
  disabled?: boolean;
};

export const PartnerSelectionInput = ({ changeValue, disabled }: Props) => {
  const { data: partners } = usePartners();

  const partnerOptions = useMemo<ISelectOption[]>(() => {
    const list = partners?.map((partner) => ({
      value: partner.partnerId,
      label: partner.accountName,
    }));

    return list || [];
  }, [partners]);

  return (
    <MainRow>
      <Col xs={4}>Partner</Col>
      <Col>
        <ConditionalField
          type={SELECT}
          fieldType={KEEP_VALUE}
          name={PARTNER}
          changeValue={changeValue}
          options={partnerOptions}
          render={renderSelectField}
          maxLength={50}
          aria-label="partner"
          testID="partner-field"
          disabled={disabled}
          isClearable
        />
      </Col>
    </MainRow>
  );
};
