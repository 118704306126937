/* eslint-disable max-len, react/jsx-wrap-multilines, react/jsx-closing-tag-location, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import {
  Col, Tabs, Tab, Spinner, Row,
} from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Link } from 'react-router-dom';
import AuthContext from '../../app/authProvider';
import {
  CenteredRow,
  PersonalInfo,
  BoldColumn,
  InputColumn,
  RedLabel,
  OrangeLabel,
  GreenLabel,
  LongTextColumn,
  LearnerProfileRow,
  StylesForCollapsePanel,
  StyledTitle,
  StylesForProgramEnrollments,
} from './styled-components';
import { IRootState } from '../../shared/reducers';
import {
  LearnerInfoProps,
  ICommonLearnerInfo,
  IDispatchProps,
  IPersonalInfoComponent,
  IlearnerStateObject,
  INewFieldValue,
  ISAOption,
  ITransferCreditPermissionObject,
} from './learner-info.model';
import {
  CERTIFICATE,
  INDIVIDUAL_COURSE,
  InternalProvider,
} from '../constants/common-constants';
import FieldEditor from './editing-component/editing-component';
import ProgramStatus from './program-status/program-status';
import StartDateInput from './start-date/start-date';
import AcademicStanding from './academic-standing/academic-standing';
import LOAScheduler from './loa-scheduler/loa-scheduler';
import AdmissionParameters from './admission-parameters/admission-parameters';
import AepStatus from './aep-status/aep-status';
import { WithErrorHandling } from '../helper-components/error-handling.component';
import { WithLoading } from '../helper-components/loading.component';
import { WithPermissions } from '../helper-components/view-permission.component';
import {
  loadLearnerInfo,
  loadLearnerEnrollments,
  loadLearnerPaymentStatus,
  updateLearnerProfileFieldValue,
  updateActiveProgramEnrollment, loadLearnerFlags, updateLearnerReadmissionFlag,
} from '../../shared/actions/learner-profile.action';
import {
  getLearnerProfile,
  getLearnerProgramEnrollment,
  getLearnerError,
  getLearnerStatus,
  getLearnerInfoPermissions,
  getLearnerInfoPermissionsPending,
  getLearnerPayment,
  getLearnerPaymentPendig,
  getLearnerPaymentError,
  getActiveProgramEnrollmentId,
  getLearnerFlags, getLearnerFlagsPending,
} from '../../shared/selectors/learner-info.selector';
import { getAcademicStanding } from '../../shared/selectors/academic-standing.selector';
import { getAcademicStandingObject } from '../../shared/actions/academic-standing.action';
import UniversityDate from '../university-date';
import { getAepDocumentsState } from '../../shared/selectors/aep-documents.selector';
import { OK } from '../admissions/admissions-form/admissions-form.constants';
import AepDeadline from './aep-deadline/aep-deadline';
import { getConfiguration } from '../../shared/selectors/configuration.selector';
import { getConfigurationObject } from '../../shared/actions/configuration.action';
import { getProgramStatusLabel } from '../../shared/selectors/learner-info-edit-modal.selector';
import ProgramEnrollment from '../../models/ProgramEnrollment';
import getQueryVariable from '../helper-components/get-query-variable';
import ProgramEnrollmentFiles from './enrollment-files/program-enrollment-files';
import { getOptionsList } from '../../shared/actions/loa-scheduler.action';
import { getDropDownOptionsArray } from '../../shared/actions/learner-info-editing-modal.action';
import EnrollmentModal from './enrollment-modal/enrollment-modal';
import { getReadmissionPermissions } from '../../shared/actions/readmission.action';
import { getReadmissionPremissions } from '../../shared/selectors/readmission.selector';
import ProductName from './product-name/product-name';
import TransferCredits from './transfer-credits/transfer-credits';
import api from '../../shared/api/adminUI.api';
import errorHandling from '../helper-components/alert-component.component';
import { isApprovalPending } from './transfer-credits/transfer-credits.utils';
import { toLowerCaseProps } from '../../utils/json';
import { IIdentityInfo } from '../admissions/admissions.model';
import OfficialName from './official-name/official-name';
import Partner from './partner/partner';
import { PaymentStatus } from './payment-status/payment-status';

export const getProgramType = (productCode: string, productType: string) => {
  if (productType === 'Course') return INDIVIDUAL_COURSE;
  return productType === 'Certificate' ? CERTIFICATE : productCode;
};

const ACCESS_RESTRICTED = 'Access restricted';
const LOADING_ACCESS_PERMISSIONS = 'Loading access permissions';
const LOADING_IDENTITY_DATA = 'Loading indentity data';

const PE_PARAMETER_ERROR = 'Wrong programEnrollmentId parameter';
let isTransferActive = false;

const PersonalInfoComponent = (props: IPersonalInfoComponent): JSX.Element => {
  const {
    learnerInfo,
    learnerFlags,
    learnerFlagsPending,
    handleFieldValueChange,
    learnerInfoPermissionsObject,
    permissionPending,
    learnerPaymentStatus,
    learnerPaymentStatusPending,
    learnerPaymentStatusError,
    academicStanding,
    aepDocumentsData,
    programStatus,
    verified,
    verifiedPending,
    learnerId,
    getBearerToken,
    officialName,
  } = props;

  const { canViewEnrollmentData, canEditAepStatus, canViewAepStatus } = learnerInfoPermissionsObject;

  const missedDocuments = (
    (aepDocumentsData && aepDocumentsData.aepDocuments)
    || []
  ).filter((ad) => ad.status !== OK);
  const aepStatus = missedDocuments.length > 0;
  const isInternalEnrollment = learnerInfo.ProductProvider === InternalProvider;
  return (
    <PersonalInfo>
      <CenteredRow>
        <Col xs={6}>
          <LearnerProfileRow>
            <Col xs={6}> Official Full Name </Col>
            <LongTextColumn xs={6}>
              <WithLoading
                loading={verifiedPending}
                loadingText={LOADING_IDENTITY_DATA}
              >
                <OfficialName
                  currentValue={officialName}
                  canEditOfficialName={
                  learnerInfoPermissionsObject.canEditLearnerInfo
                }
                  learnerId={learnerId}
                  getBearerToken={getBearerToken}
                />
              </WithLoading>
            </LongTextColumn>
          </LearnerProfileRow>
          <LearnerProfileRow>
            <Col xs={6}>Email </Col>
            <LongTextColumn xs={6} id="email">
              <FieldEditor
                oldValue={learnerInfo.Email}
                propertyName="Email"
                changedFieldUrl="email"
                canEdit={
                  isInternalEnrollment
                  && learnerInfoPermissionsObject.canEditLearnerInfo
                }
                handleNewValue={handleFieldValueChange}
                fieldType="email"
              />
            </LongTextColumn>
          </LearnerProfileRow>
          <LearnerProfileRow>
            <Col xs={6}>Birth Year </Col>
            <BoldColumn xs={6} id="birthYear">
              <FieldEditor
                oldValue={learnerInfo.BirthYear.toString()}
                propertyName="BirthYear"
                changedFieldUrl="birth-year"
                canEdit={learnerInfoPermissionsObject.canEditLearnerInfo}
                handleNewValue={handleFieldValueChange}
                fieldType="number"
              />
            </BoldColumn>
          </LearnerProfileRow>
          <LearnerProfileRow>
            <Col xs={6}>Success Advisor </Col>
            <BoldColumn xs={6} id="sa">
              <FieldEditor
                oldValue={learnerInfo.SuccessAdvisorName}
                propertyName="SuccessAdvisorName"
                changedFieldUrl="success-advisor"
                canEdit={learnerInfoPermissionsObject.canEditSuccessAdvisor}
                handleNewValue={handleFieldValueChange}
                fieldType="select"
              />
            </BoldColumn>
          </LearnerProfileRow>
          <LearnerProfileRow>
            <Col xs={6}>Partner / Account </Col>
            <BoldColumn xs={6} id="partnerAccount">
              {learnerInfo.AccountName}
            </BoldColumn>
          </LearnerProfileRow>
        </Col>
        <Col xs={6}>
          <LearnerProfileRow>
            <Col xs={6}>Country Of Residence </Col>
            <BoldColumn xs={6} id="countryOfResidence">
              <FieldEditor
                oldValue={learnerInfo.CountryOfResidence}
                propertyName="Country"
                changedFieldUrl="residence-country"
                canEdit={learnerInfoPermissionsObject.canEditLearnerInfo}
                handleNewValue={handleFieldValueChange}
                fieldType="select"
              />
            </BoldColumn>
          </LearnerProfileRow>
          {learnerInfo.LmsLastLoginDate && (
            <LearnerProfileRow>
              <Col xs={6}>LMS Activity </Col>
              <BoldColumn xs={6} id="lmsActivity">
                <UniversityDate value={learnerInfo.LmsLastLoginDate} />
              </BoldColumn>
            </LearnerProfileRow>
          )}
          <LearnerProfileRow>
            <Col xs={6}>AEP Status </Col>
            <BoldColumn xs={6} id="aepStatus">
              <WithLoading
                loading={permissionPending}
                loadingText={LOADING_ACCESS_PERMISSIONS}
              >
                <WithPermissions
                  permission={canViewAepStatus}
                  noAccesText={ACCESS_RESTRICTED}
                >
                  <AepStatus
                    canViewAepStatus={canViewAepStatus}
                    canEditAepStatus={canEditAepStatus}
                  />
                </WithPermissions>
              </WithLoading>
            </BoldColumn>
            {aepStatus && (
              <>
                <Col xs={6}>AEP Deadline </Col>
                <BoldColumn xs={6} id="aepStatus">
                  <WithLoading
                    loading={permissionPending}
                    loadingText={LOADING_ACCESS_PERMISSIONS}
                  >
                    <WithPermissions
                      permission={canViewAepStatus}
                      noAccesText={ACCESS_RESTRICTED}
                    >
                      <AepDeadline
                        canViewAepDeadline={canViewAepStatus}
                        canEditAepDeadline={canEditAepStatus}
                      />
                    </WithPermissions>
                  </WithLoading>
                </BoldColumn>
              </>
            )}
          </LearnerProfileRow>
          <LearnerProfileRow>
            <Col xs={6}>Identity </Col>
            <InputColumn xs={6} id="identity">
              <WithLoading
                loading={verifiedPending}
                loadingText={LOADING_IDENTITY_DATA}
              >
                {verified ? (
                  <span>Verified</span>
                ) : (
                  <RedLabel>Unverified</RedLabel>
                )}
              </WithLoading>
            </InputColumn>
          </LearnerProfileRow>
          <PaymentStatus
            learnerPaymentStatusPending={learnerPaymentStatusPending}
            learnerPaymentStatusError={learnerPaymentStatusError}
            learnerPaymentStatus={learnerPaymentStatus}
            learnerId={learnerId}
          />
          <LearnerProfileRow>
            <Col xs={6}>Academic Standing </Col>
            <InputColumn xs={6} id="academicStanding">
              <AcademicStanding
                status={academicStanding}
                permission={canViewEnrollmentData}
                noAccesText={ACCESS_RESTRICTED}
                loading={permissionPending}
                loadingText={LOADING_ACCESS_PERMISSIONS}
                programStatus={programStatus}
              />
            </InputColumn>
          </LearnerProfileRow>
          <LearnerProfileRow>
            <Col xs={6}>Readmission Eligibility</Col>
            <WithLoading
              loading={learnerFlagsPending}
              loadingText="Loading eligibility status"
            >
              <InputColumn xs={6} id="readmissionEligibility">
                <FieldEditor
                  oldValue={learnerFlags.ReadmissionDisabled ? 'true' : 'false'}
                  propertyName="readmissionEligibility"
                  changedFieldUrl="readmission-eligibility"
                  canEdit={learnerInfoPermissionsObject.canEditLearnerInfo}
                  handleNewValue={handleFieldValueChange}
                  fieldType="select"
                  htmlLabel={{ true: <RedLabel>Not Eligible</RedLabel>, false: <GreenLabel>Eligible</GreenLabel> }}
                />
              </InputColumn>
            </WithLoading>
          </LearnerProfileRow>
          <LearnerProfileRow>
            <Col xs={6}><Link to={{ pathname: `/learners/${learnerId}/privacy-settings`, state: { officialName } }}>Privacy Settings</Link></Col>
          </LearnerProfileRow>
        </Col>
      </CenteredRow>
      <StylesForCollapsePanel>
        <Collapsible
          trigger="Additional information"
          overflowWhenOpen="inherit"
        >
          <Row>
            <Col xs={6}>
              <BoldColumn id="priorEducation">
                <StyledTitle>Prior Education</StyledTitle>
              </BoldColumn>
              <CenteredRow>
                <Col>
                  <LearnerProfileRow>
                    <Col>Instituition Name</Col>
                    <LongTextColumn>
                      <FieldEditor
                        oldValue={learnerInfo.InstitutionName}
                        propertyName="InstitutionName"
                        changedFieldUrl="institution-name"
                        canEdit={
                          learnerInfoPermissionsObject.canEditLearnerInfo
                        }
                        handleNewValue={handleFieldValueChange}
                        fieldType="input"
                      />
                    </LongTextColumn>
                  </LearnerProfileRow>
                  <LearnerProfileRow>
                    <Col>Institution Country</Col>
                    <BoldColumn id="institutionCountry">
                      <FieldEditor
                        oldValue={learnerInfo.InstitutionCountry}
                        propertyName="InstitutionCountry"
                        changedFieldUrl="institution-country"
                        canEdit={
                          learnerInfoPermissionsObject.canEditLearnerInfo
                        }
                        handleNewValue={handleFieldValueChange}
                        fieldType="select"
                      />
                    </BoldColumn>
                  </LearnerProfileRow>
                  <LearnerProfileRow>
                    <Col>Institution Language</Col>
                    <BoldColumn id="institutionlanguage">
                      <FieldEditor
                        oldValue={learnerInfo.InstitutionLanguage}
                        propertyName="InstitutionLanguage"
                        changedFieldUrl="institution-language"
                        canEdit={
                          learnerInfoPermissionsObject.canEditLearnerInfo
                        }
                        handleNewValue={handleFieldValueChange}
                        fieldType="select"
                      />
                    </BoldColumn>
                  </LearnerProfileRow>
                  <LearnerProfileRow>
                    <Col>Graduation Year</Col>
                    <BoldColumn id="graduationYear">
                      <FieldEditor
                        oldValue={(learnerInfo.GraduationYear || 0).toString()}
                        propertyName="GraduationYear"
                        changedFieldUrl="graduation-year"
                        canEdit={
                          learnerInfoPermissionsObject.canEditLearnerInfo
                        }
                        handleNewValue={handleFieldValueChange}
                        fieldType="number"
                      />
                    </BoldColumn>
                  </LearnerProfileRow>
                  <LearnerProfileRow>
                    <Col>Highest education level</Col>
                    <LongTextColumn>
                      <FieldEditor
                        oldValue={learnerInfo.HighestEducationLevel}
                        propertyName="HighestEducationLevel"
                        changedFieldUrl="education-level"
                        canEdit={
                          learnerInfoPermissionsObject.canEditLearnerInfo
                        }
                        handleNewValue={handleFieldValueChange}
                        fieldType="select"
                      />
                    </LongTextColumn>
                  </LearnerProfileRow>
                </Col>
              </CenteredRow>
            </Col>
            <Col xs={6}>
              <BoldColumn id="personalInformation">
                <StyledTitle>Personal Information</StyledTitle>
              </BoldColumn>
              <CenteredRow>
                <Col>
                  <LearnerProfileRow>
                    <Col>Gender </Col>
                    <BoldColumn id="gender">
                      <FieldEditor
                        oldValue={learnerInfo.Gender}
                        propertyName="Gender"
                        changedFieldUrl="gender"
                        canEdit={
                          learnerInfoPermissionsObject.canEditLearnerInfo
                        }
                        handleNewValue={handleFieldValueChange}
                        fieldType="select"
                      />
                    </BoldColumn>
                  </LearnerProfileRow>
                </Col>
              </CenteredRow>
            </Col>
          </Row>
        </Collapsible>
      </StylesForCollapsePanel>
    </PersonalInfo>
  );
};

class LearnerInfo extends React.PureComponent<
ICommonLearnerInfo,
IlearnerStateObject
> {
  constructor(props: ICommonLearnerInfo) {
    super(props);
    this.state = {
      activeIndex: null,
      approvedCredits: 0,
      pendingCredits: 0,
      externalTransfers: [],
      identityVerified: false,
      identityVerifiedPending: true,
      externalTransfersPending: true,
      isInitialLoad: false,
      canApproveExternalTransfer: false,
      canEditExternalTransfer: false,
      canViewExternalTransfer: false,
      officialName: '',
    };
  }

  public componentDidMount(): void {
    const {
      getLearnerInfoData,
      getLearnerFlagsData,
      getLearnerPaymentStatus,
      getAcademicStanding: getAS,
      getConfiguration: getConfig,
      getLoaSelectOptionsList: getSelectOptionsList,
      getLearnerProgramEnrollmentsList: getLearnerProgramEnrollments,
      getProgramStatusDropDownOptionsList,
      getReadmissionPermissionsData: getReadmissionPermissionsObject,
    } = this.props;
    const { getBearerToken, learnerId } = this.context;
    getLearnerInfoData(getBearerToken, learnerId);
    getLearnerFlagsData(getBearerToken, learnerId);
    getLearnerProgramEnrollments(getBearerToken, learnerId);
    getLearnerPaymentStatus(getBearerToken, learnerId);
    getAS(getBearerToken, learnerId);
    getConfig(getBearerToken);
    getSelectOptionsList(getBearerToken);
    getProgramStatusDropDownOptionsList(getBearerToken);
    getReadmissionPermissionsObject(getBearerToken);
    this.getTransferCreditData();
    this.getIdentityInfoData();
  }

  public componentDidUpdate(): void {
    const { learnerProgramEnrollments } = this.props;
    const { isInitialLoad } = this.state;
    const latestProgram = learnerProgramEnrollments.find((x) => x.isLatest);
    const programEnrollmentID = getQueryVariable('programEnrollmentId');
    const transfer = window.location.pathname.includes('/transfers');

    if (transfer && !isInitialLoad) {
      isTransferActive = true;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isInitialLoad: true });
    }

    if (latestProgram && !programEnrollmentID && !isInitialLoad && !transfer) {
      this.handleQueryChange(latestProgram.programEnrollmentId);
    }
    if (transfer) {
      const dashboardTabs = document.getElementById('dashboard-tabs');

      if (dashboardTabs) {
        dashboardTabs.classList.add('d-none');
      }
    }
  }

  private handleFieldValueChange = async (
    newValue: INewFieldValue | ISAOption,
    changedFieldUrl: string,
  ): Promise<void> => {
    const { learnerId, getBearerToken } = this.context;
    const { setNewLearnerProfileFieldValue, updateLearnerFlags } = this.props;

    if (changedFieldUrl === 'readmission-eligibility') {
      // @ts-ignore
      const payload = { Value: newValue.readmissionEligibility === 'true' };
      await updateLearnerFlags(
        learnerId,
        getBearerToken,
        payload,
      );
    } else {
      await setNewLearnerProfileFieldValue(
        learnerId,
        getBearerToken,
        newValue,
        changedFieldUrl,
      );
    }
  };

  private handleQueryChange = (enrollmentId: string): void => {
    const queryParams = new URLSearchParams(window.location.search);

    if (!isTransferActive) {
      queryParams.set('programEnrollmentId', enrollmentId);
      this.deleteTransferParameter();
      // @ts-ignore
      window.history.replaceState(null, '', `?${decodeURIComponent(queryParams)}`);
    } else {
      this.setTransferParameter();
    }
  };

  private setTransferParameter = (): void => {
    const { learnerId } = this.context;
    const currentPath = window.location.pathname;
    const filteredPath = currentPath.substring(
      0,
      currentPath.indexOf(learnerId) + learnerId.length,
    );

    window.history.replaceState({}, '', `${filteredPath}/transfers`);
  };

  private deleteTransferParameter = (): void => {
    const currentPath = window.location.pathname;
    const filteredPath = currentPath.split('/transfers')[0];

    window.history.replaceState({}, '', filteredPath);
  };

  private readonly getTransferCreditData = async (): Promise<void> => {
    await this.getTransferCreditsPermissions();

    const { canViewExternalTransfer } = this.state;
    if (canViewExternalTransfer) {
      await this.getLearnerExternalTransfers();
    }
  };

  private readonly getLearnerExternalTransfers = async (): Promise<void> => {
    try {
      const { learnerId, getBearerToken } = this.context;
      const response = await api.transferCredits.getLearnerExternalTransfers(
        learnerId,
        getBearerToken,
      );
      if (!response.ok) throw await response;

      const externalTransfers = await response.json();
      if (externalTransfers) {
        const { approvedCredits, pendingCredits, courseTransfers } = externalTransfers;
        this.setState({ approvedCredits, pendingCredits });
        if (courseTransfers && courseTransfers.length) {
          // @ts-ignore
          this.setState({ externalTransfers: courseTransfers });
        }
      }
      this.setState({
        externalTransfersPending: false,
      });
    } catch (error) {
      errorHandling({ error });
      this.setState({
        externalTransfersPending: false,
      });
    }
  };

  private readonly getIdentityInfoData = async (): Promise<void> => {
    try {
      const { learnerId, getBearerToken } = this.context;
      const response = await api.learnerIdentity.getIdentityInfo(
        learnerId,
        getBearerToken,
      );
      if (!response.ok) throw await response;

      const identityInfo: IIdentityInfo = toLowerCaseProps(
        await response.json(),
      );
      if (identityInfo) {
        const { process } = identityInfo;
        this.setState({
          identityVerified: process.processSuccess,
          officialName: identityInfo.officialName,
        });
      }
      this.setState({
        identityVerifiedPending: false,
      });
    } catch (error) {
      errorHandling({ error });
      this.setState({
        identityVerifiedPending: false,
      });
    }
  };

  private readonly getTransferCreditsPermissions = async (): Promise<void> => {
    try {
      const { getBearerToken } = this.context;
      const response = await api.transferCredits.getExternalTransferPermissions(
        getBearerToken,
      );
      if (!response.ok) throw await response;
      const permissions = (await response.json()) as ITransferCreditPermissionObject;

      this.setState({
        canApproveExternalTransfer: permissions.canApproveExternalTransfer,
        canEditExternalTransfer: permissions.canEditExternalTransfer,
        canViewExternalTransfer: permissions.canViewExternalTransfer,
      });
    } catch (error) {
      errorHandling({ error });
      this.setState({
        canApproveExternalTransfer: false,
        canEditExternalTransfer: false,
        canViewExternalTransfer: false,
      });
    }
  };

  render(): JSX.Element {
    const {
      learnerInfo,
      learnerFlags,
      learnerFlagsPending,
      learnerProgramEnrollments,
      learnerInfoPending,
      error,
      learnerInfoPermissionsObject,
      learnerInfoPermissionsObjectPending,
      learnerPaymentStatus,
      learnerPaymentStatusPending,
      learnerPaymentStatusError,
      academicStanding,
      aepDocumentsData,
      configuration,
      setActiveProgramEnrollment,
      activeProgramEnrollmentId,
      readmissionPermissionsData,
    } = this.props;
    const {
      identityVerified,
      identityVerifiedPending,
      activeIndex,
      externalTransfersPending,
      approvedCredits,
      pendingCredits,
      externalTransfers,
      canApproveExternalTransfer,
      canEditExternalTransfer,
      canViewExternalTransfer,
      officialName,
    } = this.state;
    const { learnerId, getBearerToken } = this.context;

    const { canViewEnrollmentData, canEditAepStatus, canViewAepStatus } = learnerInfoPermissionsObject;

    learnerProgramEnrollments.sort((a, b) => {
      const cond = b.enrolledDateTime > a.enrolledDateTime ? 1 : 0;
      return a.enrolledDateTime > b.enrolledDateTime ? -1 : cond;
    });
    const approvalPending = isApprovalPending(externalTransfers);
    const programEnrollmentID = getQueryVariable('programEnrollmentId');
    const isPELoaded = learnerProgramEnrollments
      && Object.keys(learnerProgramEnrollments).length !== 0;
    const latestProgram = learnerProgramEnrollments.find((x) => x.isLatest);
    const latestProgramStatus = () => {
      if (latestProgram) {
        return latestProgram.programStatusName;
      }
      return '';
    };

    function removeParam(parameter: string) {
      let url = document.location.href;
      const urlParts = url.split('?');

      if (urlParts.length >= 2) {
        const urlBase = urlParts.shift();
        const queryString = urlParts.join('?');

        const prefix = `${encodeURIComponent(parameter)}=`;
        const pars = queryString.split(/[&;]/g);
        // eslint-disable-next-line no-plusplus
        for (let i = pars.length; i-- > 0;) {
          if (pars[i].lastIndexOf(prefix, 0) !== -1) pars.splice(i, 1);
        }
        url = pars.length
          ? `${urlBase}?${pars.join('&')}`
          : (urlBase as string);
        window.history.replaceState('', document.title, url); // added this line to push the new url directly to url bar .
      }
      return url;
    }

    const learnerPELength = learnerProgramEnrollments.length;

    const handleSelect = (key: any) => {
      const pePrefix = 'programEnrollment-';
      // eslint-disable-next-line radix
      const keyIndex = parseInt(key.replace(pePrefix, ''));
      const index = parseInt(key.replace('programEnrollment-', ''), 10);
      const selectedProgramObject = learnerProgramEnrollments.find(
        (x, i) => i === index,
      );
      const selectedPE = selectedProgramObject && selectedProgramObject.programEnrollmentId;
      const dashboardTabs = document.getElementById('dashboard-tabs');

      if (key === pePrefix + learnerPELength) {
        this.setState({ activeIndex: learnerPELength });
        isTransferActive = true;
        removeParam('programEnrollmentId');
        // @ts-ignore
        if (dashboardTabs) {
          dashboardTabs.classList.add('d-none');
        }
      } else {
        // this.setState({ isTransferActive: false });
        isTransferActive = false;
        // @ts-ignore
        if (dashboardTabs) {
          dashboardTabs.classList.remove('d-none');
        }
        if (selectedPE) {
          this.setState({ activeIndex: keyIndex });
          setActiveProgramEnrollment('');
        }
      }
      this.handleQueryChange(selectedPE || '');
    };

    let isAnyPeEnrolled = false;

    learnerProgramEnrollments.map((item, index) => {
      if (!isTransferActive) {
        if (item.programEnrollmentId === programEnrollmentID) {
          this.setState({ activeIndex: index });
        } else if (activeIndex) {
          this.setState({ activeIndex });
        }
      } else {
        this.setState({ activeIndex: learnerPELength });
      }
      const statusId = item.programStatusId;
      const readmissionDisallowedStatuses = [
        'enrolled',
        'onleaveofabsense',
        'suspended',
      ];
      const isStatusId = statusId
        && readmissionDisallowedStatuses.includes(statusId.toLowerCase());
      if (isStatusId) {
        isAnyPeEnrolled = true;
      }
      return activeIndex;
    });

    const isReadmissionAvailable = !isAnyPeEnrolled && readmissionPermissionsData.canViewReadmissionRequest;
    const isWrongPE = activeIndex === null;

    return (
      <WithLoading
        loading={learnerInfoPending}
        loadingText="Loading learner info..."
      >
        <WithErrorHandling error={error}>
          <PersonalInfoComponent
            officialName={officialName}
            verified={identityVerified}
            verifiedPending={identityVerifiedPending}
            academicStanding={academicStanding}
            learnerInfo={learnerInfo}
            learnerFlags={learnerFlags}
            learnerFlagsPending={learnerFlagsPending}
            learnerProgramEnrollments={learnerProgramEnrollments}
            error={error}
            learnerInfoPermissionsObject={learnerInfoPermissionsObject}
            learnerPaymentStatus={learnerPaymentStatus}
            permissionPending={learnerInfoPermissionsObjectPending}
            learnerPaymentStatusPending={learnerPaymentStatusPending}
            learnerPaymentStatusError={learnerPaymentStatusError}
            handleFieldValueChange={this.handleFieldValueChange}
            aepDocumentsData={aepDocumentsData}
            programStatus={latestProgramStatus()}
            learnerId={learnerId}
            getBearerToken={getBearerToken}
          />
          <StylesForProgramEnrollments>
            <div
              className={`program-enrollments-wrapper${
                isWrongPE ? ' pe-error' : ''
              }`}
            >
              {isPELoaded && (
                <div
                  className={`${
                    !isReadmissionAvailable ? 'program-enrollments-hidden ' : ''
                  }program-enrollments-inner`}
                >
                  {isReadmissionAvailable && (
                    <EnrollmentModal
                      identityVerified={identityVerified}
                      permissionsData={readmissionPermissionsData}
                      permissionPending={learnerInfoPermissionsObjectPending}
                      canViewAepStatus={canViewAepStatus}
                      canEditAepStatus={canEditAepStatus}
                    />
                  )}
                  <Tabs
                    defaultActiveKey={`programEnrollment-${activeIndex}`}
                    activeKey={`programEnrollment-${activeIndex}`}
                    id="programEnrollmentsTabs"
                    onSelect={handleSelect}
                  >
                    {learnerProgramEnrollments.map(
                      (programEnrollment: ProgramEnrollment, index) => {
                        const isEditable = programEnrollment.isLatest;
                        const isInternalEnrollment = programEnrollment.productProvider
                          === InternalProvider;
                        const isExternalEnrollment = !isInternalEnrollment;
                        const noAcademicStartDateText = isExternalEnrollment
                          ? 'N/A'
                          : 'NotStarted';
                        return (
                          <Tab
                            eventKey={`programEnrollment-${index}`}
                            key={`programEnrollment-${programEnrollment.programEnrollmentId}`}
                            title={
                              <div
                                className="enrollment-tab"
                                onClick={() => {
                                  setActiveProgramEnrollment(
                                    programEnrollment.programEnrollmentId,
                                  );
                                }}
                                role="contentinfo"
                              >
                                <h3 className="enrollment-type">
                                  {programEnrollment.displayName}
                                </h3>
                                <p className="enrollment-status">
                                  {programEnrollment.programStatusName
                                    || programEnrollment.programStatusId}
                                </p>
                              </div>
                            }
                          >
                            {activeProgramEnrollmentId
                              === programEnrollment.programEnrollmentId && (
                              <CenteredRow>
                                <Col xs={6}>
                                  <LearnerProfileRow>
                                    <Col xs={6}>Program</Col>
                                    <BoldColumn xs={6} id="program">
                                      <ProductName
                                        productType={
                                          programEnrollment.productType
                                        }
                                        productCode={
                                          programEnrollment.productCode
                                        }
                                        productProvider={
                                          programEnrollment.productProvider
                                        }
                                        productVersion={
                                          programEnrollment.productVersion
                                        }
                                      />
                                    </BoldColumn>
                                  </LearnerProfileRow>
                                  <LearnerProfileRow>
                                    <Col xs={6}>Enrollment Completion Date</Col>
                                    <BoldColumn xs={6} id="enrollmentDate">
                                      <WithLoading
                                        loading={
                                          learnerInfoPermissionsObjectPending
                                        }
                                        loadingText={LOADING_ACCESS_PERMISSIONS}
                                      >
                                        <WithPermissions
                                          permission={canViewEnrollmentData}
                                          noAccesText={ACCESS_RESTRICTED}
                                        >
                                          <UniversityDate
                                            value={
                                              programEnrollment.enrolledDateTime
                                            }
                                            skipTimezoneConversion
                                          />
                                        </WithPermissions>
                                      </WithLoading>
                                    </BoldColumn>
                                  </LearnerProfileRow>
                                  <LearnerProfileRow>
                                    <Col xs={6}>Program Status </Col>
                                    <BoldColumn xs={6} id="programStatus">
                                      {programEnrollmentID && (
                                        <ProgramStatus
                                          programEnrollmentId={
                                            programEnrollmentID
                                            || programEnrollment.programEnrollmentId
                                          }
                                          canEditProgramStatus={
                                            isEditable
                                            && learnerInfoPermissionsObject.canEditProgramStatus
                                          }
                                          permission={canViewEnrollmentData}
                                          programStatus={
                                            programEnrollment.programStatusName
                                            || '-'
                                          }
                                          noAccesText={ACCESS_RESTRICTED}
                                          loading={
                                            learnerInfoPermissionsObjectPending
                                          }
                                          loadingText={
                                            LOADING_ACCESS_PERMISSIONS
                                          }
                                        />
                                      )}
                                    </BoldColumn>
                                  </LearnerProfileRow>
                                  <LearnerProfileRow>
                                    <Col xs={6}>Acceptance Status </Col>
                                    <BoldColumn xs={6} id="acceptanceStatus">
                                      {programEnrollmentID && (
                                        <AdmissionParameters
                                          programEnrollmentId={
                                            programEnrollmentID
                                            || programEnrollment.programEnrollmentId
                                          }
                                          acceptanceStatus={
                                            programEnrollment.acceptanceStatus
                                          }
                                        />
                                      )}
                                    </BoldColumn>
                                  </LearnerProfileRow>
                                  <LearnerProfileRow>
                                    <Col xs={6}>Partner</Col>
                                    <BoldColumn xs={6} id="partner">
                                      <Partner
                                        partnerId={programEnrollment.partnerId}
                                        partnerAccountName={programEnrollment.partnerAccountName}
                                      />
                                    </BoldColumn>
                                  </LearnerProfileRow>
                                </Col>
                                <Col xs={6}>
                                  <LearnerProfileRow>
                                    <Col xs={6}>Start Date</Col>
                                    <Col
                                      xs={6}
                                      id="startDate"
                                      style={{ fontWeight: 'bold' }}
                                    >
                                      <StartDateInput
                                        canEditProgramStatus={
                                          isEditable
                                          && learnerInfoPermissionsObject.canEditStartDate
                                        }
                                        daysBeforeNextPeriod={
                                          configuration.daysBeforeNextPeriod
                                        }
                                        maxDeferralMonths={
                                          configuration.maxDeferralMonths
                                        }
                                        disableStartDateAfterXDays={
                                          configuration.disableStartDateAfterXDays
                                        }
                                        permission={canViewEnrollmentData}
                                        startDate={programEnrollment.startDate}
                                        comment={programEnrollment.comment}
                                        programEnrollmentId={
                                          programEnrollment.programEnrollmentId
                                        }
                                        enrolledDateTime={
                                          programEnrollment.enrolledDateTime
                                        }
                                        noAccesText={ACCESS_RESTRICTED}
                                        loading={
                                          learnerInfoPermissionsObjectPending
                                        }
                                        loadingText={LOADING_ACCESS_PERMISSIONS}
                                      />
                                    </Col>
                                  </LearnerProfileRow>
                                  {isInternalEnrollment && (
                                    <LearnerProfileRow>
                                      <Col xs={6}> LOA Scheduler</Col>
                                      <Col
                                        xs={6}
                                        id="loaScheduler"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        {programEnrollmentID && (
                                          <WithLoading
                                            loading={
                                              learnerPaymentStatusPending
                                            }
                                            loadingText="Loading LOA scheduler..."
                                          >
                                            <LOAScheduler
                                              programEnrollmentId={
                                                programEnrollmentID
                                                || programEnrollment.programEnrollmentId
                                              }
                                              canEditLOA={
                                                isEditable
                                                && learnerInfoPermissionsObject.canEditProgramStatus
                                              }
                                              permission={canViewEnrollmentData}
                                              noAccesText={ACCESS_RESTRICTED}
                                              learnerPaymentStatus={learnerPaymentStatus === 'unpaid'}
                                              loading={
                                                learnerInfoPermissionsObjectPending
                                              }
                                              loadingText={
                                                LOADING_ACCESS_PERMISSIONS
                                              }
                                            />
                                          </WithLoading>
                                        )}
                                      </Col>
                                    </LearnerProfileRow>
                                  )}
                                  <LearnerProfileRow>
                                    <Col xs={6}>Academic Start Date</Col>
                                    <BoldColumn xs={6} id="academicStartDate">
                                      <WithLoading
                                        loading={
                                          learnerInfoPermissionsObjectPending
                                        }
                                        loadingText={LOADING_ACCESS_PERMISSIONS}
                                      >
                                        <WithPermissions
                                          permission={canViewEnrollmentData}
                                          noAccesText={ACCESS_RESTRICTED}
                                        >
                                          {isInternalEnrollment
                                          && programEnrollment.academicStartDate ? (
                                            <UniversityDate
                                              value={
                                                programEnrollment.academicStartDate
                                              }
                                              skipTimezoneConversion
                                            />
                                            ) : (
                                              noAcademicStartDateText
                                            )}
                                        </WithPermissions>
                                      </WithLoading>
                                    </BoldColumn>
                                  </LearnerProfileRow>
                                  <LearnerProfileRow>
                                    <Col xs={12} id="programEnrollmentFiles">
                                      <ProgramEnrollmentFiles
                                        learnerId={learnerId}
                                        getBearerToken={getBearerToken}
                                        catalogAddendumFile={
                                          programEnrollment.catalogAddendumFile
                                        }
                                        catalogFile={
                                          programEnrollment.catalogFile
                                        }
                                        catalogVersion={
                                          programEnrollment.catalogVersion
                                        }
                                        enrollmentAgreementFile={
                                          programEnrollment.enrollmentAgreementFile
                                        }
                                      />
                                    </Col>
                                  </LearnerProfileRow>
                                </Col>
                              </CenteredRow>
                            )}
                          </Tab>
                        );
                      },
                    )}
                    {canViewExternalTransfer && (
                      <Tab
                        eventKey={`programEnrollment-${learnerPELength}`}
                        title={
                          <div className="enrollment-tab" role="contentinfo">
                            <h3 className="enrollment-type">
                              Transfer Credits
                            </h3>
                            {externalTransfersPending ? (
                              <Spinner size="sm" animation="border" />
                            ) : (
                              <p className="enrollment-status">
                                {approvedCredits} credits / {pendingCredits}{' '}
                                credits{' '}
                                {approvalPending && (
                                  <span>pending approval</span>
                                )}
                              </p>
                            )}
                          </div>
                        }
                      >
                        {!externalTransfersPending && (
                          <TransferCredits
                            canApproveExternalTransfer={
                              canApproveExternalTransfer
                            }
                            canEditExternalTransfer={canEditExternalTransfer}
                            onSubmit={this.getLearnerExternalTransfers}
                            getBearerToken={getBearerToken}
                            learnerId={learnerId}
                            externalTransfers={externalTransfers}
                            learnerProgramEnrollments={
                              learnerProgramEnrollments
                            }
                          />
                        )}
                      </Tab>
                    )}
                  </Tabs>
                  {isWrongPE && (
                    <div className="pe-error-text">
                      <h3>Error!</h3>
                      <pre>{PE_PARAMETER_ERROR}</pre>
                    </div>
                  )}
                </div>
              )}
            </div>
          </StylesForProgramEnrollments>
        </WithErrorHandling>
      </WithLoading>
    );
  }
}

LearnerInfo.contextType = AuthContext;

const mapStateToProps = (state: IRootState): LearnerInfoProps => ({
  learnerInfo: getLearnerProfile(state),
  learnerFlags: getLearnerFlags(state),
  learnerFlagsPending: getLearnerFlagsPending(state),
  learnerProgramEnrollments: getLearnerProgramEnrollment(state),
  error: getLearnerError(state),
  learnerInfoPending: getLearnerStatus(state),
  pending: getLearnerStatus(state),
  learnerInfoPermissionsObject: getLearnerInfoPermissions(state),
  learnerInfoPermissionsObjectPending: getLearnerInfoPermissionsPending(state),
  learnerPaymentStatus: getLearnerPayment(state),
  learnerPaymentStatusPending: getLearnerPaymentPendig(state),
  learnerPaymentStatusError: getLearnerPaymentError(state),
  academicStanding: getAcademicStanding(state),
  aepDocumentsData: getAepDocumentsState(state),
  configuration: getConfiguration(state),
  programStatus: getProgramStatusLabel(state),
  activeProgramEnrollmentId: getActiveProgramEnrollmentId(state),
  readmissionPermissionsData: getReadmissionPremissions(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, IDispatchProps, AnyAction>,
): IDispatchProps => ({
  getAcademicStanding: (
    token: () => Promise<string>,
    learnerId: string,
  ): void => {
    dispatch(getAcademicStandingObject(token, learnerId));
  },
  getLearnerInfoData: (
    token: () => Promise<string>,
    learnerId: string,
  ): void => {
    dispatch(loadLearnerInfo(token, learnerId));
  },
  getLearnerFlagsData: (
    token: () => Promise<string>,
    learnerId: string,
  ): void => {
    dispatch(loadLearnerFlags(token, learnerId));
  },
  updateLearnerFlags: (
    learnerId: string,
    token: () => Promise<string>,
    newValue: { Value: boolean },
  ): void => {
    dispatch(
      updateLearnerReadmissionFlag(
        learnerId,
        token,
        newValue,
      ),
    );
  },
  getLearnerProgramEnrollmentsList: (
    token: () => Promise<string>,
    learnerId: string,
  ): void => {
    dispatch(loadLearnerEnrollments(token, learnerId));
  },
  getLearnerPaymentStatus: (
    token: () => Promise<string>,
    learnerId: string,
  ): void => {
    dispatch(loadLearnerPaymentStatus(token, learnerId));
  },
  setNewLearnerProfileFieldValue: (
    learnerId: string,
    token: () => Promise<string>,
    newValue: INewFieldValue | ISAOption,
    changedFieldUrl: string,
  ): void => {
    dispatch(
      updateLearnerProfileFieldValue(
        learnerId,
        token,
        newValue,
        changedFieldUrl,
      ),
    );
  },
  getConfiguration: (token: () => Promise<string>): void => {
    dispatch(getConfigurationObject(token));
  },
  setActiveProgramEnrollment: (programEnrollmentId: string): void => {
    dispatch(updateActiveProgramEnrollment(programEnrollmentId));
  },
  getLoaSelectOptionsList: (token: () => Promise<string>): void => {
    dispatch(getOptionsList(token));
  },
  getProgramStatusDropDownOptionsList: (token: () => Promise<string>): void => {
    dispatch(getDropDownOptionsArray(token));
  },
  getReadmissionPermissionsData: (token: () => Promise<string>): void => {
    dispatch(getReadmissionPermissions(token));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LearnerInfo);
