/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Button, Modal, Col,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import './program-status-editing-modal.scss';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  Form, FormRenderProps, FormSpy, Field,
} from 'react-final-form';
import { AnyObject, FormState, FormApi } from 'final-form';
import moment from 'moment';
import { isEqual } from 'lodash';
import {
  requiredSubStatus, isEmptyValue, requiredReason, commentRequiredProgramStatus,
} from '../../../../utils/validate.utils';
import { IRootState } from '../../../../shared/reducers';
import {
  PROGRAM_STATUS,
  SUB_STATUS,
  REASON,
  COMMENT,
  DEFERRED_STATUS,
  LOA_STATUS,
  RETURN_DATE,
} from './program-status-editing-modal.constants';
import {
  setDropDownOptions,
  getDropDownOptions,
} from '../../../../shared/actions/learner-info-editing-modal.action';
import {
  getProgramStatusSelectOptions,
  getReasonSelectOptions,
  getSelectedOptionsList,
  getProgramStatusRestrictions,
} from '../../../../shared/selectors/learner-info-edit-modal.selector';
import { PaddingRow, DatePickerRow } from './styled-components';
import { SAVE, CLOSE } from '../../../constants/common-constants';
import {
  ILearnerInfoModalWindowState,
  IDispacthProps,
  ILearnerInfoEditStateProps,
  ICommonLearnerInfoEditStateProps,
  ISelectedDropdownOptions,
  IDropdownSelectedOptions,
} from './programs-status-editing-modal.model';
import {
  RenderSelectFieldProgramStatusForm,
  renderTextField,
  createOptions,
} from '../../../helper-components/form-components/form-filed-components/form-filed.components';
import {
  createSubStatusOptions,
  createProgramStatusOptions,
  createReasonOptions,
  showStartDateField,
  isOutdated,
  isDeferredOrEmpty,
  dateDropdownColourStyles,
} from './program-status-helper-functions';
import SaveChangesDialog from '../../../helper-components/form-components/save-changes-dialog';

class ProgramsStatusEditingModal extends React.PureComponent<
ICommonLearnerInfoEditStateProps,
ILearnerInfoModalWindowState> {
  constructor(props: ICommonLearnerInfoEditStateProps) {
    super(props);
    const { selectedOptionsList, restrictions } = props;
    this.state = {
      selectedOptions: props.selectedOptionsList,
      isChangedForm: false,
      saveChangesDialogVisible: false,
      reasonOptionList: [],
      subStatusesOptionList: createSubStatusOptions(
        selectedOptionsList.programStatus,
        restrictions,
      ),
    };
  }

  public componentDidMount(): void {
    const {
      getBearerToken,
      learnerId,
      programEnrollmentId,
      getDropDownSelectedOptionsList,
    } = this.props;
    getDropDownSelectedOptionsList(getBearerToken, learnerId, programEnrollmentId);
  }

  public componentDidUpdate(prevProps: ICommonLearnerInfoEditStateProps): void {
    const { selectedOptionsList } = this.props;
    const prevSelectOptions = prevProps.selectedOptionsList;

    if (JSON.stringify(selectedOptionsList) !== JSON.stringify(prevSelectOptions)) {
      this.updateSelectedOptions(selectedOptionsList);
    }
  }

  private updateSelectedOptions = (selectedOptionsList: IDropdownSelectedOptions) => {
    this.setState({ selectedOptions: selectedOptionsList });
  };

  private handleDetectChanges = (isChanged: boolean): void => {
    this.setState({ isChangedForm: isChanged });
  };

  private handleSave = (values: AnyObject, form: FormApi<IDropdownSelectedOptions>) => {
    const {
      programStatus, subStatus, reason, comment, returnDate,
    } = values;
    const {
      getBearerToken, learnerId, setSelectedDropDownOptions, restrictions,
      programEnrollmentId,
    } = this.props;
    const subStatusesOptionList = createSubStatusOptions(programStatus, restrictions);

    const optionObject = {
      programStatusId: isEmptyValue(programStatus),
      subStatusId: subStatusesOptionList.length > 0 ? isEmptyValue(subStatus) : null,
      reasonId: isEmptyValue(reason),
      [COMMENT]: comment,
      [RETURN_DATE]: [LOA_STATUS, DEFERRED_STATUS].includes(programStatus.value)
        ? moment(returnDate.label).format('YYYY-MM-DD') : null,
    };

    setSelectedDropDownOptions(getBearerToken, learnerId, programEnrollmentId, optionObject);
    this.closeAllModals(form, false);
  };

  private closeFrom = (form: FormApi<IDropdownSelectedOptions>, formResetNeeded: boolean) => {
    const { handleClose } = this.props;
    handleClose();
    if (formResetNeeded) {
      form.reset();
    }
  };

  private openSaveChangesDialog = (form: FormApi<IDropdownSelectedOptions>) => {
    const { isChangedForm } = this.state;
    if (isChangedForm) {
      this.handleSaveChangesModal(true);
    } else {
      this.closeFrom(form, true);
    }
  };

  private closeAllModals = (form: FormApi<IDropdownSelectedOptions>, formResetNeeded: boolean) => {
    this.closeFrom(form, formResetNeeded);
    this.handleSaveChangesModal(false);
  };

  private updateSubStatusesOptionList = (form: AnyObject): void => {
    const { restrictions } = this.props;
    const { subStatusesOptionList } = this.state;
    const { programStatus, subStatus } = form.getState().values;
    const newSubStatusesOptionList = createSubStatusOptions(programStatus, restrictions);
    if (JSON.stringify(newSubStatusesOptionList) !== JSON.stringify(subStatusesOptionList)) {
      this.setState({ subStatusesOptionList: newSubStatusesOptionList, reasonOptionList: [] });
    }
    if (restrictions && programStatus && subStatus) {
      const programStatusOb = restrictions.find((x) => x.programStatus.id === programStatus.value);
      if (programStatusOb) {
        const subStatusOb = programStatusOb.subStatuses.find((x) => x.id === subStatus.value);
        if (subStatusOb) {
          const { reasons } = subStatusOb;
          this.setState({ reasonOptionList: reasons });
        } else {
          this.setState({ reasonOptionList: [] });
        }
      }
    }
  };

  private handleSaveChangesModal(saveChangesDialogVisible: boolean) {
    this.setState({ saveChangesDialogVisible });
  }

  public render(): JSX.Element {
    const {
      selectedOptions, saveChangesDialogVisible, subStatusesOptionList, reasonOptionList,
    } = this.state;
    const {
      show,
      handleClose,
      programStatusSelectOptions,
      selectedOptionsList,
    } = this.props;

    const {
      programStatus,
      returnDate,
    } = selectedOptionsList;

    const initialFormValues = {
      [PROGRAM_STATUS]: selectedOptions[PROGRAM_STATUS],
      [RETURN_DATE]: selectedOptions[RETURN_DATE],
      [SUB_STATUS]: selectedOptions[SUB_STATUS],
      [REASON]: selectedOptions[REASON],
      [COMMENT]: selectedOptions[COMMENT],
    };

    return (
      <Modal show={show} onHide={handleClose} backdrop="static" animation={false}>
        <Modal.Header>
          <Modal.Title>Editing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form<IDropdownSelectedOptions>
            onSubmit={this.handleSave}
            initialValues={initialFormValues}
            keepDirtyOnReinitialize
            render={({
              handleSubmit,
              form,
              valid,
              values,
              initialValues,
              invalid,
            }) => (
              <form onSubmit={handleSubmit}>
                <Col>
                  <PaddingRow>
                    <Col>Program status</Col>
                    <Col>
                      <Field
                        render={RenderSelectFieldProgramStatusForm}
                        name={PROGRAM_STATUS}
                        fieldName="status"
                        options={
                          createProgramStatusOptions(
                            programStatusSelectOptions,
                            initialFormValues[PROGRAM_STATUS],
                          )
                        }
                      />
                    </Col>
                  </PaddingRow>
                </Col>
                {form.getState().values[PROGRAM_STATUS].value !== LOA_STATUS && (
                  <>
                    <Col>
                      {
                        showStartDateField(form.getState().values[PROGRAM_STATUS].value) && (
                          <DatePickerRow>
                            {programStatus.value === LOA_STATUS ? (
                              <Col>Return date</Col>
                            ) : (
                              <Col>Start date</Col>
                            )}
                            <Col>
                              <Field
                                name={RETURN_DATE}
                                isDisabled
                                render={RenderSelectFieldProgramStatusForm}
                                className={isOutdated(returnDate.value) ? 'outdated' : ''}
                                styles={dateDropdownColourStyles}
                              />
                            </Col>
                          </DatePickerRow>
                        )
                      }
                    </Col>
                    {subStatusesOptionList.length > 0
                      && (
                        <Col>
                          <PaddingRow>
                            <Col>Sub-status</Col>
                            <Col>
                              <Field
                                render={RenderSelectFieldProgramStatusForm}
                                name={SUB_STATUS}
                                fieldName="subStatus"
                                isProgramStatusSelect={false}
                                validate={requiredSubStatus}
                                options={createOptions(subStatusesOptionList)}
                              />
                            </Col>
                          </PaddingRow>
                        </Col>
                      )}
                    {reasonOptionList && reasonOptionList.length > 0
                      && (
                      <Col>
                        <PaddingRow>
                          <Col>Reason</Col>
                          <Col>
                            <Field
                              render={RenderSelectFieldProgramStatusForm}
                              name={REASON}
                              validate={requiredReason}
                              options={createReasonOptions(reasonOptionList)}
                            />
                          </Col>
                        </PaddingRow>
                      </Col>
                      )}

                    <Col>
                      <PaddingRow>
                        <Col>Comments</Col>
                        <Col>
                          <Field
                            name={COMMENT}
                            validate={commentRequiredProgramStatus}
                            displayError
                            maxLength="500"
                            placeholder="Comment"
                            render={renderTextField}
                          />
                        </Col>
                      </PaddingRow>
                    </Col>
                  </>
                )}
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={
                      isDeferredOrEmpty(programStatus)
                      || isEqual(initialValues, values)
                      || invalid
                    }

                  >
                    {SAVE}
                  </Button>
                  <Button variant="secondary" onClick={() => this.openSaveChangesDialog(form)}>
                    {CLOSE}
                  </Button>
                </Modal.Footer>
                <SaveChangesDialog
                  show={saveChangesDialogVisible}
                  formValid={valid}
                  closeModals={this.closeAllModals}
                  text="Your changes have not been saved"
                  form={form}
                  formResetNeeded
                  handleSubmit={handleSubmit}
                />
                <FormSpy
                  subscription={{ values: true, dirty: true, pristine: true }}
                  onChange={<K extends object>({ dirty }: FormState<K>): void => {
                    this.handleDetectChanges(dirty);
                    this.updateSubStatusesOptionList(form);
                  }}
                />
              </form>
            )}
          />

        </Modal.Body>
      </Modal>
    );
  }
}
const mapStateToProps = (state: IRootState): ILearnerInfoEditStateProps => ({
  programStatusSelectOptions: getProgramStatusSelectOptions(state),
  reasonSelectOptions: getReasonSelectOptions(state),
  restrictions: getProgramStatusRestrictions(state),
  selectedOptionsList: getSelectedOptionsList(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, IDispacthProps, AnyAction>,
): IDispacthProps => (
  {
    setSelectedDropDownOptions: (token: () => Promise<string>, learnerId: string,
      programEnrollmentId: string,
      optionObject: ISelectedDropdownOptions): void => {
      dispatch(setDropDownOptions(token, learnerId, programEnrollmentId, optionObject));
    },
    getDropDownSelectedOptionsList: (token: () => Promise<string>, learnerId: string,
      programEnrollmentId: string): void => {
      dispatch(getDropDownOptions(token, learnerId, programEnrollmentId));
    },

  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgramsStatusEditingModal);
