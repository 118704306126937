import React, { Suspense } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import {
  GreenLabel,
  OrangeLabel,
  RedLabel,
  BoldColumn,
  LearnerProfileRow,
} from '../styled-components';
import styles from './payment-status.module.scss';

import { WithLoading } from '../../helper-components/loading.component';
import { WithErrorHandling } from '../../helper-components/error-handling.component';
import IError from '../../../models/Error';
import { FinancialSuspension } from './financial-suspension';

type ContentProps = {
  learnerPaymentStatus: string;
};

const Content = ({ learnerPaymentStatus }: ContentProps) => {
  if (learnerPaymentStatus === 'paid') {
    return <GreenLabel>Paid</GreenLabel>;
  }

  if (learnerPaymentStatus === 'partial') {
    return <OrangeLabel>Met Minimum Payment</OrangeLabel>;
  }

  return <RedLabel>Unpaid</RedLabel>;
};

type Props = {
  learnerPaymentStatusPending: boolean;
  learnerPaymentStatusError: IError | null;
  learnerPaymentStatus: string;
  learnerId: string;
};

export const PaymentStatus = ({
  learnerPaymentStatusPending,
  learnerPaymentStatusError,
  learnerPaymentStatus,
  learnerId,
}: Props) => (
  <LearnerProfileRow>
    <Col xs={6}> Payment Status </Col>
    <BoldColumn xs={6} id="paymentStatus">
      {' '}
      <WithLoading
        loading={learnerPaymentStatusPending}
        loadingText="Loading payment info..."
      >
        <WithErrorHandling error={learnerPaymentStatusError} small>
          <div className={styles.row}>
            <Content learnerPaymentStatus={learnerPaymentStatus} />
            <Suspense fallback={<Spinner size="sm" animation="border" />}>
              <FinancialSuspension learnerId={learnerId} />
            </Suspense>
          </div>
        </WithErrorHandling>
      </WithLoading>
    </BoldColumn>
  </LearnerProfileRow>
);
