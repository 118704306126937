import React, { useEffect, useState } from 'react';
import { Field, useField } from 'react-final-form';
import cn from 'classnames';
import { FaAsterisk } from 'react-icons/fa';
import { FieldProps } from './form-field';
import styles from './form-field.module.scss';
import { PartnerLogoImagePreview } from './partner-logo-image-preview';

export type FormFileFieldProps = {} & FieldProps<FileList>;

export const FormFileField = ({
  name,
  label,
  helperText,
  className,
  required,
  reverse,
  accept,
  ...rest
}: FormFileFieldProps) => {
  const { meta } = useField(name);
  const [image, setImage] = useState<string | null>(null);

  const hasError = meta.error && meta.invalid && (meta.submitFailed || meta.submitSucceeded);

  const changeImage = (file: File | null | undefined) => {
    if (file) {
      // needs for initial value
      if (file.size < 1) {
        setImage(file.name);
        return;
      }

      setImage(URL.createObjectURL(file));
      return;
    }

    setImage(null);
  };

  useEffect(() => {
    changeImage(rest.initialValue?.item(0));
  }, [rest.initialValue]);

  return (
    <div className={cn(styles.field, reverse && styles.reverse, className)}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {required ? (
            <FaAsterisk className={styles.asterisk} size={5} />
          ) : (
            <span />
          )}
          <span>{label}</span>
        </label>
      )}
      <div className={styles.content}>
        <Field<FileList> name={name} {...rest}>
          {({ input: { value, onChange, ...input } }) => (
            <input
              {...input}
              type="file"
              data-testid={name}
              onChange={({ target }) => {
                changeImage(target.files?.item(0));
                onChange(target.files);
              }}
              accept={accept}
            />
          )}
        </Field>
        {helperText && <span className={styles.description}>{helperText}</span>}
        {hasError && (
          <span className={cn(styles.description, styles.error)}>
            {meta.error}
          </span>
        )}
      </div>
      {image && <PartnerLogoImagePreview image={image} />}
    </div>
  );
};
