import React from 'react';
import { SectionLabel } from './styled-components';

type Props = {
  section?: string;
};

export const PathSectionLabel = ({ section }: Props) => {
  if (!section || section === 'Capstone') return null;

  return <SectionLabel>{section}</SectionLabel>;
};
