import React from 'react';
import {
  Button, Modal, Col, Spinner,
} from 'react-bootstrap';
import {
  Form,
  Field,
} from 'react-final-form';
import { CLOSE, SAVE } from '../../../constants/common-constants';
import {
  FileUpload,
  renderDataPicker,
  renderTextField,
  renderTextInput,
  Error,
} from '../../../helper-components/form-components/form-filed-components/form-filed.components';
import {
  IAepExtensionEditorState, IProps,
} from './aep-extension-editor-modal.model';
import { FormRow } from '../../../learner-files/styled-component';
import AuthContext from '../../../../app/authProvider';
import {
  AEP_DEADLINE_DATE, COMMENT, FILE_NAME, UPLOAD_DATE, UPLOAD_FIELD,
} from './aep-extension-editor-modal.constants';
import getFileValidationErrors from '../../../learner-files/file-upload-modal/file-validation-functions';
import { minLength } from '../../../../utils/validate.utils';


class AepExtensionEditor extends React.PureComponent<IProps, IAepExtensionEditorState> {
  constructor(props: IProps) {
    super(props);
    const { aepDeadline, comment } = this.props;

    this.state = {
      aepFormValues: {
        aepDeadlineDate: aepDeadline,
        comment,
        fileName: '',
        uploadField: null,
        uploadedDate: new Date(),
      },
    };
  }

  public render(): JSX.Element {
    const {
      showModal,
      handleSave,
      handleClose,
      aepExtensionPending,
      aepMinDeadline,

    } = this.props;

    const { aepFormValues } = this.state;

    return (
      <Modal
        show={showModal}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>AEP Extension</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSave}
            initialValues={aepFormValues}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            validate={(values) => {
              const errors: { [key: string]: string | string[] } = {};
              const file = values[UPLOAD_FIELD]
                ? values[UPLOAD_FIELD]![0]
                : null;
              if (file) {
                if (getFileValidationErrors(file).length > 0) {
                  errors[UPLOAD_FIELD] = getFileValidationErrors(file);
                }
                if (!values[UPLOAD_DATE]) {
                  errors[UPLOAD_DATE] = 'Select upload date';
                }
                if (!values[FILE_NAME]) {
                  errors[FILE_NAME] = 'Provide file name';
                }
              }

              if (!values[AEP_DEADLINE_DATE]) {
                errors[AEP_DEADLINE_DATE] = 'Provide deadline date';
              }

              if (!values[COMMENT]) {
                errors[COMMENT] = 'Provide comment';
              }

              return errors;
            }}
            render={({
              handleSubmit,
              pristine,
              invalid,
              values,
              errors,
              form,
            }) => (
              <form
                onSubmit={(e) => { e.preventDefault(); }}
              >
                <>
                  <div>
                    <FormRow>
                      <Col xs={3}>
                        <span>AEP Deadline</span>
                      </Col>
                      <Col xs={9}>
                        <Field
                          render={renderDataPicker}
                          minDate={aepMinDeadline}
                          dateFormat="dd MMM yyyy"
                          name={AEP_DEADLINE_DATE}
                        />
                        <Error name={AEP_DEADLINE_DATE} />
                      </Col>
                    </FormRow>
                    <FormRow>

                      <Col xs={3}>
                        <span>Comment</span>
                      </Col>
                      <Col xs={9}>
                        <Field
                          name={COMMENT}
                          displayError
                          render={renderTextField}
                          validate={minLength(5)}
                          maxLength={250}
                        />
                      </Col>
                    </FormRow>
                    <FormRow>
                      <Col xs={3}>Upload File</Col>
                      <Col xs={9}>
                        <FileUpload
                          changeFileNameValue={form.mutators.setValue}
                          name={UPLOAD_FIELD}
                        />
                        <Error name={UPLOAD_FIELD} multipleErrors />
                      </Col>
                    </FormRow>
                    {values[UPLOAD_FIELD] && !errors?.[UPLOAD_FIELD] && (
                      <>
                        <FormRow>
                          <Col xs={3}>File Name</Col>
                          <Col xs={9}>
                            <Field
                              name={FILE_NAME}
                              render={renderTextInput}
                              maxLength={50}
                            />
                            <Error name={FILE_NAME} />
                          </Col>
                        </FormRow>
                        <FormRow>
                          <Col xs={3}>Upload Date</Col>
                          <Col xs={9}>
                            <Field
                              name={UPLOAD_DATE}
                              showMonthYearPicker={false}
                              render={renderDataPicker}
                              dateFormat="MMMM d, yyyy"
                              maxDate={new Date()}
                              showCurrentTime
                            />
                            <Error name={UPLOAD_DATE} />
                          </Col>
                        </FormRow>
                      </>
                    )}
                  </div>
                </>
                <Modal.Footer>
                  <Button
                    disabled={pristine || invalid || aepExtensionPending}
                    variant="primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {SAVE}
                    {aepExtensionPending && <Spinner size="sm" animation="border" />}
                  </Button>
                  <Button
                    disabled={aepExtensionPending}
                    variant="secondary"
                    onClick={() => handleClose(false)}
                  >
                    {CLOSE}
                  </Button>
                </Modal.Footer>
              </form>
            )}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

AepExtensionEditor.contextType = AuthContext;

export default AepExtensionEditor;
