import { IAdmissionDataObject } from '../../components/admissions/admissions-form/admissions-form.model';
import {
  ICoreCoursesOrderElement,
} from '../../components/learning-path/steps/courses-summary/courses-summary.model';
import { ILoaDataObject } from '../../components/learner-info/loa-scheduler/loa-scheduler-modal/loa-scheduler-model.model';
import { AdminSubmitIdentityId, AdminSubmitIdentityIdV2 } from '../../components/admissions/admissions.model';
import { IUploadedFile } from '../../models/UploadedFile';
import { IUpdateAepDocumentsRequest } from '../../components/learner-files/learner-files.model';
import { IExtendAepDeadlineRequest } from '../../components/learner-info/aep-deadline/aep-deadline.model';
import { TransferableCoursesFormValues } from '../../components/learner-info/learner-info.model';
import { featureFlags } from '../../config.json';
import { DocumentInfo } from '../models/identity-info';
import { EducationProfileResponse, PersonalDetailsResponse } from '../../models/LearnerRegistration';
import {
  Agreement,
  AgreementPropertyValues, CreatePartnerAgreementRequest, CreatePartnerPayload, PartnerInfo,
} from '../../models/Partner';
import { AppError } from './app-error';
import { CreatePartnerCodePayload, PartnerCode } from '../../models/PartnerCode';
import ProgramEnrollment from '../../models/ProgramEnrollment';
import IPartnersPermission from '../../models/PartnersPermissions';

export const LEARNER_FILE_TYPE = 'learnerFile';
export const ADMISSION_FILE_TYPE = 'admissionFile';
export const APPLICANT_FILE_TYPE = 'applicantFile';


export interface IUploadFormData {
  fileName: string;
  uploadDate: string;
  category: string;
  subcategory: string;
  comment: string;
}

export interface IUploadData extends IUploadFormData {
  uploadedFileName: string;
}

export interface IProgramStatusData {
  program: string;
  acceptanceStatus: string;
  foundationCourses: string[];
  comment: string;
}

interface IUnenrollDataObject {
  status: string;
  subStatus: string | null,
  comment: string;
  dropRequested: boolean;
}

interface IEnrollmentFormData {
  AcceptanceStatus: string;
  IsSapPlanRequired: boolean;
  LearnerId: string;
  ProductCode: string;
  ProductType: string;
  StartDate: string;
  AEPDocuments: string;
}

export interface AvailabilityPermissionResponse {
  canEditAvaialbilities: boolean;
}

export interface AvailabilityResult {
  productCode: string;
  productType: string;
  availableMonths: Array<Number>;
  year: number;
}

const getFileSubmitUrlbyFileType = (fileType: string, learnerId: string): string => {
  switch (fileType) {
    case APPLICANT_FILE_TYPE:
      return `/api/admission/applicant-files/${learnerId}/files`;
    case LEARNER_FILE_TYPE:
    default:
      return `/api/learner-files/learners/${learnerId}/files`;
  }
};

const getIdentityUrlByFlag = (learnerId: string): string => {
  const useIdentityService:boolean = featureFlags.useIdentityService === 'true';
  if (useIdentityService === true) {
    return `/api/learner-identity-api/learners/${learnerId}/identity-info`;
  }
  return `/api/admission/applicants/${learnerId}/application/identity`;
};

export default {
  admissions: {
    getAdmissionPermission: async (getBearerToken:() => Promise<string>) => {
      const token = await getBearerToken();
      const response = await fetch('/api/admission/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getFXRatePermission: async (getBearerToken: () => Promise<string>) => {
      const token = await getBearerToken();
      const response = await fetch('/api/learner-payments-api/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getCountries: async (getBearerToken:() => Promise<string>) => {
      const token = await getBearerToken();
      const response = await fetch('/api/admission/countries', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getStates: async (getBearerToken:() => Promise<string>) => {
      const token = await getBearerToken();
      const response = await fetch('/api/admission/states', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getNationalities: async (getBearerToken:() => Promise<string>) => {
      const token = await getBearerToken();
      const response = await fetch('/api/admission/nationalities', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getApplicantVoucherCodeData: async (
      getBearerToken:() => Promise<string>,
      applicantId: string,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/voucher-codes`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getAdmissionData: async (
      applicantId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application-decision-options`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getApplicantInfo: async (
      applicantId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },
    setAdmissionData: async (
      applicantId: string,
      getBearerToken:() => Promise<string>,
      admissionData: IAdmissionDataObject,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application-decision`, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(admissionData),
      });
      if (!response.ok) throw await response;

      return response;
    },
    getEducationInfo: async (
      applicantId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application/education-info`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;

      return response;
    },
    getNationalityInfo: async (
      applicantId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application/nationality-info`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;

      return response;
    },
    getPersonalInfo: async (
      applicantId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application/personal-info`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },
    getPersonalInfoEditableApplicant: async (
      applicantId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/info`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getIdentityInfo: async (
      applicantId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application/identity`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },
    SubmitIdentityId: async (
      applicantId: string,
      getBearerToken:() => Promise<string>,
      identityId: AdminSubmitIdentityId,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application/identity/id`, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(identityId),
      });
      if (!response.ok) throw await response;
      return response;
    },
    deleteLearnerFile: async (
      learnerId: string,
      fileName: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/learner-files/${learnerId}/files/${fileName}`, {
        method: 'DELETE',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    editLearnerFileProperties: async (
      learnerId: string,
      updatedFile: IUploadedFile,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/learner-files/${learnerId}/files`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(updatedFile),
      });
      return response;
    },

    getProgramFrequencies: async (getBearerToken:() => Promise<string>, productType: string, productCode: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/tuition-fee/frequency/${productType}/${productCode}`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },

  },
  graduation: {
    getCredentialsByRereference: (referenceId: string, token: string) => fetch(`/api/graduation/credentials/${referenceId}`, {
      method: 'GET',
      headers: [['Authorization', `Bearer ${token}`]],
    }),
  },
  learnerDetails: {
    getLearnerDetails: async (
      learnerId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/enrollment/learners/${learnerId}/details`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  learningPathPlanner: {
    userProgressInfo: {
      getUserProgressInfo: async (
        learnerId: string,
        programEnrollmentId: string,
        getBearerToken: () => Promise<string>,
      ) => {
        const token = await getBearerToken();
        const response = await fetch(`/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path/status`, {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        });
        return response;
      },
    },
    coursesSummary: {
      getCoursesSummaryData: async (
        learnerId: string,
        programEnrollmentId: string,
        getBearerToken: () => Promise<string>,
      ) => {
        const token = await getBearerToken();
        const response = await fetch(`/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path`, {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        });
        return response;
      },
      setSelectedCoursesSummaryData: async (
        learnerId: string,
        programEnrollmentId: string,
        getBearerToken:() => Promise<string>,
        courseOrder: ICoreCoursesOrderElement[],
      ) => {
        const token = await getBearerToken();
        const response = await fetch(`/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path`, {
          method: 'PUT',
          headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
          body: JSON.stringify(courseOrder),
        });
        return response;
      },
    },
    specializtions: {
      getSpecializtionsData: async (
        learnerId: string,
        programEnrollmentId: string,
        getBearerToken:() => Promise<string>,
      ) => {
        const token = await getBearerToken();
        const response = await fetch(`/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path/specialisations`, {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        });
        return response;
      },
      setSelectedSpecializtions: async (
        learnerId: string,
        programEnrollmentId: string,
        getBearerToken:() => Promise<string>,
        specialisationsData: string[],
      ) => {
        const token = await getBearerToken();
        const response = await fetch(`/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path/specialisations`, {
          method: 'PUT',
          headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
          body: JSON.stringify(specialisationsData),
        });
        return response;
      },
    },
    electives: {
      getElectivesData: async (
        learnerId: string,
        programEnrollmentId: string,
        getBearerToken: () => Promise<string>,
      ) => {
        const token = await getBearerToken();
        const response = await fetch(`/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path/electives`, {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        });
        return response;
      },
      setSelectedElectives: async (
        learnerId: string,
        programEnrollmentId: string,
        getBearerToken:() => Promise<string>,
        electivesData: string[],
      ) => {
        const token = await getBearerToken();
        const response = await fetch(`/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path/electives`, {
          method: 'PUT',
          headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
          body: JSON.stringify(electivesData),
        });
        return response;
      },
    },
  },
  billing: {
    getLearnerPaymentStatus: async (
      learnerId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/billing/customers/${learnerId}/status`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getBillingPermissions: async (
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/billing/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  transcript: {
    getTranscriptHtml: async (
      learnerId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/transcript/learners/${learnerId}/transcript/html`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  loaScheduler: {
    getLoaStatus: async (
      learnerId: string,
      programEnrollmentId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/program/learners/${learnerId}/program-enrollments/${programEnrollmentId}/schedule`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    updateLoaStatus: async (
      learnerId: string,
      programEnrollmentId: string,
      getBearerToken:() => Promise<string>,
      loaData: ILoaDataObject,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/program/learners/${learnerId}/program-enrollments/${programEnrollmentId}/schedule`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(loaData),
      });
      return response;
    },
    cancelLoaStatus: async (
      learnerId: string,
      programEnrollmentId: string,
      getBearerToken:() => Promise<string>,
      scheduleId?: null | string,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/program/learners/${learnerId}/program-enrollments/${programEnrollmentId}/schedule/${scheduleId}`, {
        method: 'DELETE',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getOptionsList: async (
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/program/schedule/options', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  transferCredits: {
    getExternalTransferPermissions: async (
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/external-transfer/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getListOfProviders: async (
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/external-transfer/providers', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getListOfTransferableProducts: async (
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/external-transfer/transferable-products', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getListOfProductsTransferableCourses: async (
      getBearerToken:() => Promise<string>,
      learnerId: string,
      productType: string,
      productCode: string,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/external-transfer/learners/${learnerId}/products/${productType}/${productCode}/transferable-courses`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getLearnerExternalTransfers: async (
      learnerId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/external-transfer/learners/${learnerId}/transfered-courses`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    saveLearnerPendingExternalTransfers: async (
      learnerId: string,
      getBearerToken:() => Promise<string>,
      transferedCourses: TransferableCoursesFormValues,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/external-transfer/learners/${learnerId}/transfered-courses`, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`]],
        body: JSON.stringify(transferedCourses),
      });
      return response;
    },
    approveLearnerPendingExternalTransfers: async (
      learnerId: string,
      getBearerToken:() => Promise<string>,
      transferedCourses: TransferableCoursesFormValues,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/external-transfer/learners/${learnerId}/transfered-courses/approved`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`]],
        body: JSON.stringify(transferedCourses),
      });
      return response;
    },
  },
  academicPerformance: {
    getLearnerGradesPermission: async (
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/performance/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getLearnerGrades: async (
      learnerId: string,
      programEnrollmentId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/performance/learners/${learnerId}/program-enrollments/${programEnrollmentId}/performance`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getCoursePerformance: async (
      getBearerToken:() => Promise<string>,
      learnerId: string,
      courseCode: string,
      startYear: number,
      startMonth: number,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/grades/learners/${learnerId}/courses/${courseCode}/${startYear}-${startMonth}/performance`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getCourseStatusEditOptions: async (
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/enrollment/course-enrollment/restrictions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    unenrollLearnerFromCourse: async (
      learnerId: string,
      programEnrollmentId: string,
      getBearerToken: () => Promise<string>,
      courseCode: string,
      startDate: string,
      unenrollData: IUnenrollDataObject,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/enrollment/course-enrollment/${learnerId}/program-enrollments/${programEnrollmentId}/courses/${courseCode}/${startDate}`, {
        method: 'PUT',
        body: JSON.stringify(unenrollData),
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
      });
      return response;
    },
    updateDropRequestStatus: async (
      learnerId: string,
      programEnrollmentId: string,
      getBearerToken: () => Promise<string>,
      courseCode: string,
      startDate: string,
      dropRequestDecision: string,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/enrollment/course-enrollment/${learnerId}/program-enrollments/${programEnrollmentId}/courses/${courseCode}/${startDate}/dropRequest`, {
        method: 'PUT',
        body: JSON.stringify({ status: dropRequestDecision }),
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
      });
      return response;
    },
    deleteLearnerCourse: async (
      learnerId: string,
      programEnrollmentId: string,
      getBearerToken: () => Promise<string>,
      courseCode: string,
      startDate: string,
      comment: string,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/enrollment/course-enrollment/${learnerId}/program-enrollments/${programEnrollmentId}/courses/${courseCode}/${startDate}`, {
        method: 'DELETE',
        body: JSON.stringify({ comment }),
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
      });
      return response;
    },
  },
  history: {
    getLearnerHistories: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(
        `/api/history/learners/${learnerId}`,
        {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        },
      );
      return response;
    },
    getLearnerPrivacySettingsHistory: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(
        `/api/history/learners/${learnerId}/privacy-settings`,
        {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        },
      );
      return response;
    },
  },
  documentUpload: {
    previewFile: async (
      getBearerToken: () => Promise<string>,
      url: string,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getLearnerFilesCategoryOptions: async (
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/learner-files/categories', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getApplicantFilesCategoryOptions: async (
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/admission/applicant-files/categories', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getAdmissionsFiles: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/admission/learner-files/${learnerId}/files`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });

      return response;
    },
    getApplicantFiles: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/applicant-files/${learnerId}/files`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });

      return response;
    },
    getLearnerFiles: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-files/learners/${learnerId}/files`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    deleteLearnerFile: async (
      learnerId: string,
      fileName: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-files/learners/${learnerId}/files/${fileName}`, {
        method: 'DELETE',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    editLearnerFileProperties: async (
      learnerId: string,
      updatedFile: IUploadedFile,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-files/learners/${learnerId}/files`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(updatedFile),
      });
      return response;
    },
    getLearnerFilePermission: async (
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/learner-files/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    submitUploadForm: async (
      learnerId: string,
      getBearerToken:() => Promise<string>,
      fileType: string,
      uploadFormData: IUploadData,
    ) => {
      const token = await getBearerToken();
      const url = getFileSubmitUrlbyFileType(fileType, learnerId);
      const response = await fetch(url, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(uploadFormData),
      });
      return response;
    },
  },
  learnerProfile: {
    getResidenceCountryOptions: async (
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/learner-profile/residence-country', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;

      return response;
    },

    getSuccessAdvisorOptions: async (
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/learner-profile/success-advisor', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;

      return response;
    },
  },
  aepDocuments: {
    aepDocumentOptions: async (
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();

      const response = await fetch('/api/admission/aepdocuments', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    learnerAepDocuments: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/admission/applicants/${learnerId}/aepdocuments`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    updateLearnerAepDocuments: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
      updatedDocuments: IUpdateAepDocumentsRequest,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${learnerId}/aepdocuments`, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(updatedDocuments),
      });
      return response;
    },
  },
  aepDeadline: {
    learnerAepDeadline: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/admission/applicants/${learnerId}/aep-deadline`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    extendLearnerAepDeadline: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
      updatedDocuments: IExtendAepDeadlineRequest,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${learnerId}/aep-deadline`, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(updatedDocuments),
      });
      return response;
    },
  },
  academicStanding: {
    learnerAcademicStanding: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/sap/learners/${learnerId}/sap-status`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  privacySettings: {
    current: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-profile/learners/${learnerId}/privacy-setting`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  program: {
    configuration: async (
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/program/configuration', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },


    getFileForReadmission: async (
      learnerId: string,
      updatedFile: any,
      ext: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-files/learners/${learnerId}/files/temp/${ext}`, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: updatedFile,
      });
      return response;
    },

    learnerLatestProgram: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/program/learners/${learnerId}/latest-program`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    admissionParameters: async (
      learnerId: string,
      programEnrollmentId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/program/learners/${learnerId}/program-enrollments/${programEnrollmentId}/admission-parameters`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  readmission: {
    getReadmissionEligibility: async (
      learnerId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-profile/learners/${learnerId}/flag-settings`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw response;
      return response;
    },
    putReadmissionEligibility: async (
      learnerId: string,
      payload: { Value: boolean },
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      return fetch(`/api/learner-profile/learners/${learnerId}/flag-settings/readmission-disabled`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(payload),
      });
    },
    createLearnerReadmissionRequest: async (
      learnerId: string,
      enrollmentFormData: IEnrollmentFormData,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/readmission/learners/${learnerId}/readmission-request`, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(enrollmentFormData),
      });
      return response;
    },

    approveLearnerReadmissionRequest: async (
      learnerId: string,
      enrollmentFormData: IEnrollmentFormData,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/readmission/learners/${learnerId}/readmission-request/active/approved`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(enrollmentFormData),
      });
      return response;
    },

    cancelLearnerReadmissionRequest: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/readmission/learners/${learnerId}/readmission-request/active/canceled`, {
        method: 'DELETE',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },

    updateLearnerReadmissionRequest: async (
      learnerId: string,
      enrollmentFormData: IEnrollmentFormData,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/readmission/learners/${learnerId}/readmission-request/active`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(enrollmentFormData),
      });
      return response;
    },

    getLearnerActiveReadmissionRequest: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/readmission/learners/${learnerId}/readmission-request/active`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },

    getPermissions: async (
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/readmission/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },

    learnerProducts: async (
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/readmission/products', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },

  learnerIdentity: {
    SubmitIdentityInfo: async (
      learnerId: string,
      getBearerToken:() => Promise<string>,
      identityInfo: AdminSubmitIdentityIdV2,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-identity-api/learners/${learnerId}/identity-info`, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(identityInfo),
      });
      if (!response.ok) throw await response;
      return response;
    },

    SubmitIdentityPhoto: async (
      learnerId: string,
      getBearerToken:() => Promise<string>,
      photoDocument: DocumentInfo,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-identity-api/learners/${learnerId}/documents/photo`, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(photoDocument),
      });
      if (!response.ok) throw await response;
      return response;
    },

    getIdentityInfo: async (
      learnerId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const url = getIdentityUrlByFlag(learnerId);
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },
    updateOfficialName: async (learnerId: string, getBearerToken: () => Promise<string>, officialName: string) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/learner-identity-api/learners/${learnerId}/official-name/by-admin`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify({ officialName }),
      });
      return response;
    },
  },

  seminars: {

    getPermissions: async (
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/product-availability-api/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    getSeminarAvailabilityByYear: async (
      year: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const url = `/api/product-availability-api/product-availability/${year}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    updateSeminarAvailabilityByYear: async (
      year: string,
      payload: Array<AvailabilityResult>,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const url = `/api/product-availability-api/product-availability/${year}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(payload),
      });
      if (!response.ok) throw await response;
      return response;
    },
  },

  learnerRegistration: {
    getActiveRegistrations: async (
      applicantId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const url = `/api/registration/learners/${applicantId}/registrations`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    getAdmittanceStatus: async (
      applicantId: string,
      registrationId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const url = `/api/admittance-api/learners/${applicantId}/admittance/${registrationId}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    getProductList: async (
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const url = '/api/requirements/versions/latest/products';
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    acceptAdmittance: async (
      applicantId: string,
      registrationId: string,
      getBearerToken:() => Promise<string>,
      payload?: {
        productType: string,
        productCode: string
        comment: string,
        partnerId?: string,
      },
    ) => {
      const token = await getBearerToken();
      const url = `/api/adminaggregator/learners/${applicantId}/admittance/${registrationId}/accept`;
      return fetch(url, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: payload ? JSON.stringify(payload) : null,
      });
    },

    rejectAdmittance: async (
      applicantId: string,
      registrationId: string,
      payload: {
        comment: string
      },
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const url = `/api/adminaggregator/learners/${applicantId}/admittance/${registrationId}/reject`;
      return fetch(url, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(payload),
      });
    },

    undoAdmittance: async (
      applicantId: string,
      registrationId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const url = `/api/admittance-api/learners/${applicantId}/admittance/${registrationId}/undo`;
      return fetch(url, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
      });
    },

    getPersonalDetails: async (
      applicantId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const url = `/api/learner-profile/v2/learners/${applicantId}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    updatePersonalDetails: async (
      applicantId: string,
      payload: PersonalDetailsResponse,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      return fetch(`/api/learner-profile/learners/${applicantId}`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(payload),
      });
    },


    getIdentityInfo: async (
      learnerId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();

      const url = `/api/learner-identity-api/learners/${learnerId}/identity-info`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },


    getEducationInfo: async (
      applicantId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const url = `/api/learner-profile/v2/learners/${applicantId}/education-info`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    updateEducationInfo: async (
      applicantId: string,
      payload: EducationProfileResponse,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      return fetch(`/api/learner-profile/v2/learners/${applicantId}/education-info`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify(payload),
      });
    },

    getEnglishProficiency: async (
      applicantId: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const url = `/api/learner-profile/learners/${applicantId}/english-proficiency`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    getLearnerFiles: async (
      learnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-files/learners/${learnerId}/files`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

  },

  fxRates: {
    getRateWithHistory: async (
      currency: string,
      getBearerToken:() => Promise<string>,
    ) => {
      const url = `/api/learner-payments-api/fx-rates/${currency}`;
      const token = await getBearerToken();
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    updateFXRate: async (
      currency: string,
      newRate: number,
      effectiveFrom: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const url = `/api/learner-payments-api/fx-rates/${currency}`;
      const token = await getBearerToken();
      const response = await fetch(url, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`]],
        body: JSON.stringify({ newRate, effectiveFrom }),
      });
      if (!response.ok) throw await response;
      return response;
    },
  },

  partners: {
    getPermissions: async (
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch('/api/partners-api/v1/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });

      const permissions = await response.json();
      if (!response.ok) throw AppError.fromError(permissions, response.status);

      return permissions as IPartnersPermission;
    },
    getPartners: async (
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();

      const response = await fetch('/api/partners-api/v1/partners', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });

      if (response.status === 204) return [];

      const partners = await response.json();
      if (!response.ok) throw AppError.fromError(partners, response.status);

      return partners as PartnerInfo[];
    },
    createPartner: async (
      payload: CreatePartnerPayload,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();

      const form = new FormData();
      form.append('accountName', payload.accountName);
      form.append('email', payload.email);
      if (payload.contactName) {
        form.append('contactName', payload.contactName);
      }
      if (payload.logo) {
        form.append('logo', payload.logo, payload.logo.name);
      }
      const response = await fetch('/api/adminaggregator/v1/partners', {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`]],
        body: form,
      });

      if (!response.ok) throw AppError.fromError('Fail to create partner', response.status);
    },
    updatePartner: async (
      partnerId: string,
      payload: CreatePartnerPayload,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();

      const form = new FormData();
      form.append('accountName', payload.accountName);
      form.append('email', payload.email);
      if (payload.contactName) {
        form.append('contactName', payload.contactName);
      }
      if (payload.logo) {
        form.append('logo', payload.logo, payload.logo.name);
      }

      const response = await fetch(`/api/adminaggregator/v1/partners/${partnerId}`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`]],
        body: form,
      });

      if (!response.ok) throw AppError.fromError('Fail to update partner', response.status);
    },
    getPartner: async (
      partnerId: string,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/partners-api/v1/partners/${partnerId}`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });

      const partner = await response.json();
      if (!response.ok) throw AppError.fromError(partner, response.status);

      return partner as PartnerInfo;
    },

    getLatestAgreement: async (
      partnerId: string,
      getBearerToken: () => Promise<string>,
    ): Promise<Agreement | null> => {
      const token = await getBearerToken();
      const response = await fetch(`/api/partners-api/v1/partners/${partnerId}/latest-agreement`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });

      if (response.status === 204) return null;

      const agreement = await response.json();
      if (!response.ok) throw AppError.fromError(agreement, response.status);

      return agreement || null;
    },

    createAgreement: async (
      partnerId: string,
      payload: CreatePartnerAgreementRequest,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/partners-api/v1/partners/${partnerId}/agreements`, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`]],
        body: JSON.stringify(payload),
      });

      const partner = await response.json();
      if (!response.ok) throw AppError.fromError(partner, response.status);

      return partner as PartnerInfo;
    },

    updateAgreement: async (
      partnerId: string,
      agreementId: string,
      payload: CreatePartnerAgreementRequest,
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/partners-api/v1/partners/${partnerId}/agreements/${agreementId}`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`]],
        body: JSON.stringify(payload),
      });

      const partner = await response.json();
      if (!response.ok) throw AppError.fromError(partner, response.status);

      return partner as PartnerInfo;
    },

    getPropertyValues: async (
      getBearerToken: () => Promise<string>,
    ) => {
      const token = await getBearerToken();

      const response = await fetch('/api/adminaggregator/v1/partners/agreement-property-values', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });

      const productGroups = await response.json();
      if (!response.ok) throw AppError.fromError(productGroups, response.status);

      return productGroups as AgreementPropertyValues;
    },

    programEnrollments: async (partnerId: string, getBearerToken: () => Promise<string>) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/program/partners/${partnerId}/program-enrollments`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });

      if (response.status === 204) return [];

      const programEnrollments = await response.json();
      if (!response.ok) throw AppError.fromError(programEnrollments, response.status);

      return programEnrollments as ProgramEnrollment[];
    },

    codes: {
      getBatches: async (partnerId: string, getBearerToken: () => Promise<string>) => {
        const token = await getBearerToken();

        const response = await fetch(`/api/partners-codes-api/v1/partners/${partnerId}/batches`, {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        });

        if (response.status === 204) return [];

        const codes = await response.json();
        if (!response.ok) throw AppError.fromError(codes, response.status);

        return codes as PartnerCode[];
      },
      getCSV: async (batchId: number, getBearerToken: () => Promise<string>) => {
        const token = await getBearerToken();

        const response = await fetch(`/api/partners-codes-api/v1/batches/${batchId}/codes/csv`, {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        });

        const file = await response.blob();
        if (!response.ok) throw AppError.fromError(file, response.status);

        return file;
      },
      create: async (payload: CreatePartnerCodePayload, getBearerToken: () => Promise<string>) => {
        const token = await getBearerToken();

        const response = await fetch('/api/partners-codes-api/v1/batches', {
          method: 'POST',
          headers: [['Authorization', `Bearer ${token}`]],
          body: JSON.stringify(payload),
        });

        const codes = await response.json();
        if (!response.ok) throw AppError.fromError(codes, response.status);

        return codes as PartnerCode;
      },
    },
  },
};
