export const START_DATE = 'startDate';
export const RADIO = 'radio';
export const SELECT = 'select';
export const TEXT = 'text';
export const PROGRAM_TYPE = 'programType';
export const PROGRAM_CODE = 'programCode';
export const ACCEPTANCE_TYPE = 'acceptanceType';
export const FOUNDATION_COURSES = 'foundationCourses';
export const IS_SAP_REQUIRED = 'isSapRequired';
export const SAP_YES = 'yes';
export const SAP_YES_LABEL = 'Yes';
export const SAP_NO = 'no';
export const SAP_NO_LABEL = 'No';
export const PROVISIONAL = 'provisional';
export const FULL = 'full';
export const UPLOAD_FIELD = 'uploadFile';
export const PAYMENT_FREQUENCY = 'paymentFrequency';
export const DISCOUNT_CODE = 'discountCode';
export const PARTNER = 'partner';
export const KEEP_VALUE = 'keepValue';

export const ACCESS_RESTRICTED = 'Access restricted';
export const LOADING_ACCESS_PERMISSIONS = 'Loading access permissions';

export const PENDING_REQUEST_STATE = 'pending';
export const READY_REQUEST_STATE = 'ready';
export const UNSIGNED_REQUEST_STATE = 'unsigned';
