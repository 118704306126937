import React from 'react';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { PartnerAgreementFormValues } from '../../types';
import api from '../../../../../shared/api/adminUI.api';
import {
  AgreementTuition,
  CreatePartnerAgreementRequest,
} from '../../../../../models/Partner';
import { usePartnerAgreementsParams } from '../../hooks/use-params';
import { useAuthContext } from '../../../../auth/auth-context';
import { useAgreementInitialValues } from './use-initial-values';
import { useLatestAgreement } from '../../hooks/use-latest-agreement';
import { FormContent } from './form-content';

export const PartnerAgreementsForm = () => {
  const history = useHistory();
  const { partnerId } = usePartnerAgreementsParams();
  const { getBearerToken } = useAuthContext();
  const { data: latestAgreement } = useLatestAgreement();

  const initialValues = useAgreementInitialValues();

  const { mutateAsync: createAgreement, error: createError } = useMutation({
    mutationFn: (payload: CreatePartnerAgreementRequest) => api.partners.createAgreement(partnerId, payload, getBearerToken),
  });
  const { mutateAsync: updateAgreement, error: updateError } = useMutation({
    mutationFn: (payload: CreatePartnerAgreementRequest) => api.partners.updateAgreement(
      partnerId,
      latestAgreement?.agreementId!,
      payload,
      getBearerToken,
    ),
  });

  const handleSubmit = async (data: PartnerAgreementFormValues) => {
    try {
      const agreementTuitions = data.agreementTuitions
        .filter((agreement) => agreement.enabled)
        .map((agreement) => agreement.tierTypeIds
          .filter((tier) => Boolean(tier.value))
          .map<AgreementTuition>((tier) => ({
          productGroup: agreement.productId,
          tier: tier.id,
          planId: tier.value,
        })))
        .flat();

      const fn = latestAgreement ? updateAgreement : createAgreement;
      await fn({
        ...data,
        applicationFeeAmount: +data.applicationFeeAmount,
        startDate: moment(data.startDate).toISOString(),
        endDate: data.endDate ? moment(data.endDate).toISOString() : undefined,
        agreementTuitions,
      });

      toast.success(
        latestAgreement
          ? 'Agreement updated successfully'
          : 'Agreement created successfully',
      );
      history.push(`/partners/${partnerId}`);
    } catch (err) {
      console.error('createAgreement error', err);
    }
  };

  const error = createError || updateError;

  return (
    <FormContent
      error={error}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    />
  );
};
