import React from 'react';
import { Form as BootstrapForm } from 'react-bootstrap';
import { FormCard } from '../form-card/form-card';
import styles from '../form-content.module.scss';
import { FormField } from '../../../../components/form-field/form-field';
import { required } from '../../../../../../utils/validate.utils';
import { usePropertyValues } from '../../../hooks/use-property-values';

export const ApplicationFee = () => {
  const { data: propertyValues } = usePropertyValues();

  return (
    <FormCard title="Application Fee">
      <div className={styles.applicationFee}>
        <FormField
          name="applicationFeePaidBy"
          label="Paid by"
          validate={required}
          render={({ input }) => (
            <div className={styles.row}>
              {propertyValues?.applicationInvoiceTypes.map(({ id, name }) => (
                <BootstrapForm.Check
                  key={id}
                  type="radio"
                  name="paid-by"
                  label={name}
                  value={id}
                  onChange={() => input.onChange(id)}
                  checked={input.value === id}
                  data-testid={`applicationFeePaidBy-${id}`}
                />
              ))}
            </div>
          )}
        />
        <FormField
          name="applicationFeeAmount"
          label="Price $"
          validate={required}
          type="number"
        />
      </div>
    </FormCard>
  );
};
