import React from 'react';
import DatePicker from 'react-datepicker';
import styles from '../form-content.module.scss';
import { FormField } from '../../../../components/form-field/form-field';
import {
  composeValidators,
  required,
  validateDate,
  validateDateRange,
} from '../../../../../../utils/validate.utils';
import { PartnerAgreementFormValues } from '../../../types';

export const AgreementDates = () => (
  <div className={styles.row}>
    <FormField<Date>
      name="startDate"
      label="Agreement Dates:"
      validate={composeValidators(required, validateDate)}
      render={({ input }) => (
        <DatePicker
          onChange={(date) => input.onChange(date ? date.getTime() : null)}
          selected={input.value}
          dateFormat="yyyy-MM-dd"
          id="agreement-dates-startDate"
        />
      )}
    />
    <div>-</div>
    <FormField<Date>
      name="endDate"
      validate={composeValidators(
        validateDate,
        (value: Date, allValues: PartnerAgreementFormValues) => validateDateRange(allValues.startDate, value),
      )}
      render={({ input }) => (
        <DatePicker
          onChange={(date) => input.onChange(date ? date.getTime() : null)}
          selected={input.value}
          dateFormat="yyyy-MM-dd"
          id="agreement-dates-endDate"
        />
      )}
    />
  </div>
);
