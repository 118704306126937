import { RegistrationRequirement, RequirementType } from '../models/LearnerRegistration';

export const sortedRequirements: RequirementType[] = [
  RequirementType.EducationInfo,
  RequirementType.PreviousDegreeLevel,
  RequirementType.UnofficialTranscript,
  RequirementType.PersonalInfo,
  RequirementType.EnglishProficiency,
  RequirementType.TermsAndConditions,
  RequirementType.PhotoIdentity,
  RequirementType.GovIdentity,
  RequirementType.ApplicationFee,
  RequirementType.AdmissionDecision,
  RequirementType.StartDate,
  RequirementType.Agreement,
  RequirementType.TuitionFee,
];

// Re-order the requirements array on a registration to match the sort order above
export function SortRequirements(requirements: RegistrationRequirement[]) {
  requirements.sort((a, b) => {
    if (sortedRequirements.indexOf(a.requirement) > sortedRequirements.indexOf(b.requirement)) {
      return 1;
    }

    return -1;
  });
}
