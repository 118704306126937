import React, { useRef } from 'react';
import BaseSelect, { Props as BaseProps } from 'react-select';
import { HelperText } from '../helper-text/helper-text';

type Props = {
  error?: string;
  testId?: string;
} & BaseProps;

export const Select = ({ error, testId, ...props }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const onMenuOpen = () => {
    const { current } = containerRef;
    if (!current) return;

    setTimeout(() => {
      const selectedEl = current.getElementsByClassName(
        'nxu__option--is-selected',
      )[0];

      if (selectedEl) {
        selectedEl.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }, 15);
  };

  return (
    <div ref={containerRef} data-testid={testId}>
      <BaseSelect classNamePrefix="nxu" onMenuOpen={onMenuOpen} {...props} />

      {error && <HelperText variant="error">{error}</HelperText>}
    </div>
  );
};
