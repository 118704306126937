import React, { useRef } from 'react';
import { uniqueId } from 'lodash';
import cn from 'classnames';
import styles from './switch.module.scss';

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'type'> & {
  label: React.ReactNode;
};

export const Switch = ({ label, ...rest }: Props) => {
  const id = useRef(uniqueId('switch-'));

  return (
    <div className={styles.container}>
      <input
        id={id.current}
        type="checkbox"
        {...rest}
        className={cn(styles.input, rest.className)}
      />
      <div className={styles.switch} />
      <label htmlFor={id.current} className={styles.label}>
        {label}
      </label>
    </div>
  );
};
