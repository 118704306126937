import React, { useEffect } from 'react';
import { Button, Modal, Form as BootstrapForm } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import api from '../../../../../../shared/api/adminUI.api';
import { useAuthContext } from '../../../../../auth/auth-context';
import { usePartnerCodesParams } from '../../../hooks/use-partner-codes-params';
import styles from './create-partner-codes.module.scss';
import {
  composeValidators,
  required,
  v,
} from '../../../../../../utils/validate.utils';
import { HelperText } from '../../../../../helper-text/helper-text';
import { AppError } from '../../../../../../shared/api/app-error';
import { downloadBlobFile } from '../../../../../../utils/download';
import { CodesType, codesTypeLabels, CreatePartnerCodePayload } from '../../../../../../models/PartnerCode';
import { useLatestAgreement } from '../../../../partner-agreements/hooks/use-latest-agreement';
import { TuitionFeeInvoiceType } from '../../../../../../models/Partner';
import { getCsvFileName } from '../../../utils/get-csv-file-name';

type FormValues = {
  codesCount: number;
  codesType: CodesType;
};

type Props = {
  show: boolean;
  onHide: () => void;
  accountName: string;
};

export const CreatePartnerCodes = ({ show, onHide, accountName }: Props) => {
  const { getBearerToken } = useAuthContext();
  const { partnerId } = usePartnerCodesParams();
  const client = useQueryClient();
  const { data: latestAgreement } = useLatestAgreement();

  const isDisabledPayment = !latestAgreement || latestAgreement.tuitionFeeInvoiceType !== TuitionFeeInvoiceType.PartnerBillsLearner;

  const { mutateAsync: getCSV, isLoading } = useMutation({
    mutationFn: (id: number) => api.partners.codes.getCSV(id, getBearerToken),
  });

  const {
    mutateAsync: createCode,
    error,
    reset,
  } = useMutation({
    mutationFn: (payload: CreatePartnerCodePayload) => api.partners.codes.create(payload, getBearerToken),
  });

  const onSubmit = async ({ codesCount, codesType }: FormValues) => {
    try {
      const code = await createCode({ partnerId, codesCount: Number(codesCount), codesType });
      client.invalidateQueries(['partner', partnerId, 'codes']);
      toast.success('Codes created successfully');
      onHide();

      const file = await getCSV(code.id);
      const fileName = getCsvFileName(code.id, accountName, codesType);
      downloadBlobFile(file, fileName);
    } catch (err) {
      console.error('Error creating partner codes', err);
    }
  };

  useEffect(() => {
    if (!show) {
      reset();
    }
  }, [show]);

  const appError = error ? AppError.fromError(error) : null;

  return (
    <Modal show={show} onHide={onHide} animation={false}>
      <Modal.Header className={styles.header}>
        <Modal.Title>Generate Codes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form<FormValues>
          onSubmit={onSubmit}
          destroyOnUnregister
          initialValues={{ codesType: CodesType.Enrollment }}
          render={({
            handleSubmit,
            submitting,
            errors,
            submitFailed,
            submitSucceeded,
            invalid,
          }) => {
            const hasError = !!errors?.codesCount
              && invalid
              && (submitFailed || submitSucceeded);

            return (
              <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.row}>
                  <span className={styles.text}>Type</span>

                  <Field
                    name="codesType"
                    type="radio"
                    value={CodesType.Enrollment}
                    render={({ input }) => (
                      <BootstrapForm.Check
                        inline
                        label={codesTypeLabels[CodesType.Enrollment]}
                        type="radio"
                        id={CodesType.Enrollment}
                        value={input.value}
                        checked={input.checked}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        onFocus={input.onFocus}
                      />
                    )}
                  />

                  <Field
                    name="codesType"
                    type="radio"
                    value={CodesType.Payment}
                    render={({ input }) => (
                      <BootstrapForm.Check
                        inline
                        disabled={isDisabledPayment}
                        label={codesTypeLabels[CodesType.Payment]}
                        type="radio"
                        id={CodesType.Payment}
                        value={input.value}
                        checked={input.checked}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        onFocus={input.onFocus}
                      />
                    )}
                  />
                </div>

                <div className={styles.row}>
                  <span className={styles.text}>Generate</span>

                  <Field
                    className={styles.input}
                    component="input"
                    validate={composeValidators(
                      required,
                      v.num.min(1),
                      v.num.fraction(0, 'must be a whole number'),
                    )}
                    name="codesCount"
                    type="number"
                  />
                </div>

                {hasError ? (
                  <HelperText variant="error">{errors?.codesCount}</HelperText>
                ) : null}

                {appError ? (
                  <HelperText variant="error">{appError.message}</HelperText>
                ) : null}

                <Modal.Footer className={styles.footer}>
                  <Button variant="secondary" onClick={onHide}>
                    Cancel
                  </Button>
                  <Button variant="success" type="submit" disabled={submitting}>
                    Submit
                  </Button>
                </Modal.Footer>
              </form>
            );
          }}
        />
      </Modal.Body>
    </Modal>
  );
};
