import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { EnrollmentBehaviour } from '../../steps/courses-summary/courses-summary.model';

const StandaloneTooltip = () => (
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip id="capstone-tooltip">Standalone</Tooltip>}
  >
    <FaInfoCircle size={20} />
  </OverlayTrigger>
);

type Props = {
  enrollmentBehaviour: EnrollmentBehaviour;
};

export const EnrollmentBehaviuorLabel = ({ enrollmentBehaviour }: Props) => {
  if (enrollmentBehaviour === 'Standalone') return <StandaloneTooltip />;

  return null;
};
